/* stylesheet */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap');
:root {
  --primary-color: #e5f0f5;
  --secondary-color: #e8ba22;
  --dark-color1: #031d2e;
  --dark-color2: #17384e;
  --white-color: #ffffff;
  --transition: 0.4s ease-in-out;
}

:after,
:before {
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.form-control:focus {
  color: #000000;
  background-color: #ffffff;
  outline: 0;
  box-shadow: none;
  border: 1px solid #b3b3b3;
}

body {
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  background-color: #f2f2f2;
  color: var(--primary-color);
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Philosopher', sans-serif;
  font-weight: 400;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s linear;
}

.required:after {
  content: " *";
  color: red;
}

.hide {
  display: none !important;
}

/* common css */
.as_padderTop10 {
  padding-top: 10px;
}

.as_padderTop20 {
  padding-top: 20px;
}

.as_padderTop30 {
  padding-top: 30px;
}

.as_padderTop40 {
  padding-top: 40px;
}

.as_padderTop50 {
  padding-top: 50px;
}

.as_padderTop60 {
  padding-top: 60px;
}

.as_padderTop70 {
  padding-top: 70px;
}

.as_padderTop80 {
  padding-top: 80px;
}

.as_padderTop90 {
  padding-top: 90px;
}

.as_padderTop100 {
  padding-top: 120px;
}

.as_padderBottom5 {
  padding-bottom: 5px;
}

.as_padderBottom10 {
  padding-bottom: 10px;
}

.as_padderBottom20 {
  padding-bottom: 20px;
}

.as_padderBottom30 {
  padding-bottom: 30px;
}

.as_padderBottom40 {
  padding-bottom: 40px;
}

.as_padderBottom50 {
  padding-bottom: 50px;
}

.as_padderBottom60 {
  padding-bottom: 60px;
}

.as_padderBottom70 {
  padding-bottom: 70px;
}

.as_padderBottom80 {
  padding-bottom: 80px;
}

.as_padderBottom90 {
  padding-bottom: 90px;
}

.as_padderBottom100 {
  padding-bottom: 90px;
}

.as_margin0 {
  margin: 0px;
}

.link_url:not(.active) {
  cursor: pointer;
}

.ast_default_select .ast_select__control {
  min-height: 50px;
  box-shadow: none !important;
  background: #ffffff;
  color: #000000;
  border: 1px solid #b3b3b3;
  cursor: pointer;
}

.as_font14 {
  font-size: 14px;
  line-height: 24px;
}

.as_toggle {
  display: none;
}

.as_heading {
  font-size: 24px;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 23px;
  position: relative;
  margin: -8px 0 20px;
}

.as_heading.as_heading_center:after {
  left: 50%;
  transform: translateX(-50%);
}

.as_heading:after {
  position: absolute;
  content: '';
  width: 100px;
  border-bottom: 3px solid var(--secondary-color);
  left: 0;
  bottom: 0;
}

.form-control {
  height: 50px;
  outline: none;
  box-shadow: none !important;
  background: #ffffff;
  color: #000000;
  padding: 0 10px;
  /* border: 1px solid #b3b3b3; */
}

.form-control::placeholder {
  color: #767c90;
}

.as_subheading {
  font-size: 22px;
  text-transform: capitalize;
}

.as_orange {
  color: var(--secondary-color);
}

.as_white {
  color: var(--white-color);
}

.as_btn {
  height: 50px;
  background-color: var(--secondary-color);
  font-size: 14px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  padding: 0 23px;
  position: relative;
  margin-left: 15px;
  color: var(--white-color);
  border: none !important;
  outline: none;
  width: auto;
  transition: 0.2s linear;
  border-radius: 5px;
}

button.as_btn.log_btn {
  height: 34px;
}

.as_btn:hover:after {
  border-left-color: #01a1a4;
}

.as_btn:hover:before {
  border-right-color: #01a1a4;
}

.as_btn:hover {
  background-color: #01a1a4;
}

.as_btn:after,
.as_btn:before {
  display: none;
}

.as_btn:before {
  display: none;
}

.as_verticle_center {
  display: flex;
  align-items: center;
}

.slick-dots {
  padding: 0;
  margin: 30px 0 0 0;
  list-style: none;
}

.slick-dots li {
  display: inline-flex;
}

.slick-dots li button::before {
  content: '';
}

.slick-dots li {
  height: 9px;
  width: 9px;
  background-color: #b2373b;
  border-radius: 100%;
  margin: 0 6px;
  cursor: pointer;
}

.slick-dots li.slick-active {
  background-color: var(--secondary-color);
}

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #03999d;
  border: none;
  padding: 0 13px;
  z-index: 1;
  outline: none;
}

.slick-arrow::after {
  display: none;
}

.slick-arrow::before {
  display: none;
}

.slick-arrow:hover {
  background-color: var(--secondary-color);
}

.slick-arrow:hover:after {
  border-left-color: var(--secondary-color);
}

.slick-arrow:hover::before {
  border-right-color: var(--secondary-color);
}

.slick-arrow.slick-prev {
  left: -120px;
  padding: 0 12px 0 0px;
}

.slick-arrow.slick-prev span {
  margin-right: 10px;
  display: inline-flex;
}

.slick-arrow span svg path {
  fill: var(--secondary-color);
}

.slick-arrow:hover span svg path {
  fill: var(--white-color);
}

.slick-arrow.slick-next span {
  margin-left: 10px;
}

.slick-arrow.slick-next {
  right: -100px;
  padding: 0 0 0 12px;
}

.slick-slide {
  outline: none;
}

.only-timepicker .datepicker--nav,
.only-timepicker .datepicker--content {
  display: none;
}

.only-timepicker .datepicker--time {
  border-top: none;
}

.datepicker {
  background-color: #17384e;
  border: 1px solid rgb(255 255 255 / 10%);
  color: var(--primary-color);
}

.datepicker--nav {
  border-bottom: 1px solid rgb(255 255 255 / 10%);
}

.datepicker--nav > div {
  background-color: transparent !important;
  color: var(--primary-color);
}

.datepicker--pointer {
  background-color: #17384e;
  border-top-color: rgb(255 255 255 / 10%);
  border-right-color: rgb(255 255 255 / 10%);
}

.datepicker--cell:hover {
  background-color: var(--secondary-color);
  color: var(--white-color) !important;
}

.datepicker--cell.-current-,
.datepicker--day-name {
  color: var(--secondary-color);
}

/* select2 */
.select2-selection {
  height: 50px !important;
  background-color: var(--dark-color2) !important;
  border: 1px solid rgb(255 255 255 / 10%) !important;
  outline: none;
  box-shadow: none !important;
  color: var(--primary-color);
}

.select2-selection .select2-selection__rendered {
  color: var(--primary-color) !important;
  font-size: 14px;
  line-height: 50px !important;
  padding: 0 28px !important;
  text-align: initial;
}

.form-group {
  text-align: initial;
}

.form-group {
  text-align: initial;
  margin: 10px 0;
}

.select2-selection__clear {
  display: none;
}

.select2-container--default .select2-selection__arrow {
  height: auto !important;
  bottom: 1px;
  right: 18px !important;
}

span.select2-dropdown.select2-dropdown--above,
.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 0;
}

.select2-container {
  transition: none;
  width: 100% !important;
}

.select2-dropdown {
  transition: none;
  background-color: var(--dark-color2);
  border-color: rgb(255 255 255 / 10%);
  font-size: 14px;
}

.select2-search--dropdown {
  padding: 10px 25px;
}

.select2-search--dropdown input {
  background-color: var(--dark-color2);
  border-color: rgb(255 255 255 / 10%) !important;
  outline: none !important;
}

.select2-results__option {
  padding: 10px 26px;
  background-color: transparent !important;
}

.select2-results__option:hover,
.select2-results__option.select2-results__option--selected {
  color: var(--secondary-color) !important;
}

/* loader */
/*.as_loader{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--dark-color1);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.as_loader img{
  animation: spin 7s infinite linear;
  -webkit-animation: spin 7s infinite linear;
  -moz-animation: spin 7s infinite linear;
}*/

/*login_page_css_start*/
.as_login_area {
  background-attachment: fixed;
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(150deg, #b2147b, #b24b14);
}

.as_login_area .login-shape {
  position: absolute;
  bottom: -90px;
  left: -90px;
}

.as_login_area .login_inner {
  width: 650px;
  margin: auto;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgb(255 255 255 / 20%);
  background: #ffffff;
}

.as_login_area .login_logo img {
  width: 230px;
  margin-bottom: 30px;
  object-fit: contain;
  margin-top: 30px;
}

.as_login_area .login_logo {
  text-align: center;
}

/* .as_login_area .form-control {
  height: 50px;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none !important;
  color: #000000;
  padding: 0 20px 0 20px;
  border-bottom: 1px solid #221c4a;
  border-radius: 0;

} */

.as_login_area .login_form {
  padding: 0 50px;
}

/*login_page_css_end*/
/* header css start */
.as_header_wrapper {
  display: flex;
  background-color: #07273c;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 99999;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  background-image: linear-gradient(343deg, #b2373b, #970625);
  top: 0;
}

.as_header_wrapper .as_logo img {
  object-fit: contain;
  width: 130px;
}

.as_logo {
  padding: 4px 40px;
  border-right: 1px solid hsla(0, 0%, 100%, 0.1);
  display: flex;
  align-items: center;
  background: #fff;
}

.as_menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.as_menu ul li.login_btn a {
  background: #e8b922;
  padding: 5px 20px !important;
  color: #ffffff !important;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
  overflow: hidden;
  position: relative;
}

.as_menu ul li.login_btn a span {
  position: relative;
  z-index: 1;
}

.as_menu ul li.login_btn a:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 490%;
  width: 140%;
  background: #e8b922;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
  transform: translateX(-122%) translateY(-25%) rotate(36deg);
}

.as_menu ul li.login_btn a:hover:after {
  -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
  transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

.as_menu > ul > li {
  display: inline-flex;
  text-transform: capitalize;
  margin: 0 13px;
  position: relative;
}

.as_menu_wrapper {
  display: flex;
  border-top: 1px solid rgb(255 255 255 / 10%);
  justify-content: flex-end;
  padding-right: 15px !important;
  position: relative;
  z-index: 1;
  border-top: none;
}
/* .as_header_detail {
  width: calc(100% - 449px);
} */

.as_menu > ul > li > a {
  padding: 23px 0;
  position: relative;
  transition: all 0.3s linear;
}

.as_menu > ul > li > a:before {
  content: '';
  position: absolute;
  width: 0;
  height: 3px;
  background-color: var(--secondary-color);
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
}

.as_menu > ul > li > a:hover:before,
.as_menu > ul > li > a.active:before {
  width: 29px;
}

.as_menu ul li a:hover,
.as_menu ul li a.active {
  color: var(--secondary-color);
}

.as_info_detail {
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
}

.as_search_wrapper {
  padding-top: 22px;
  transition: all 0.3s linear;
}

.as_search {
  cursor: pointer;
  transition: all 0.3s linear;
}

.as_info_detail ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.as_info_detail ul li {
  display: inline-flex;
  padding: 15px 30px;
}

.as_info_detail ul li a,
.as_info_detail ul li a:focus {
  text-decoration: none;
  color: inherit;
}

.as_infobox .as_infoicon {
  width: 50px;
  height: 50px;
  background-color: var(--dark-color2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-right: 15px;
}

.as_infobox {
  display: flex;
  align-items: center;
  text-decoration: none !important;
}

.as_info_detail ul li .as_infobox > span {
  font-weight: 600;
  margin-right: 5px;
}

.as_right_info {
  display: flex;
  align-items: center;
}

.as_cart_wrapper {
  background-color: var(--dark-color2);
  padding: 14px 24px;
  border-radius: 50px;
  margin-left: 30px;
  position: relative;
  cursor: pointer;
}

.as_cart_wrapper > span {
  position: relative;
  margin-right: 20px;
}

.as_cart_wrapper > span .as_cartnumber {
  width: 18px;
  height: 18px;
  background-color: var(--secondary-color);
  border-radius: 100%;
  font-size: 9px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -12px;
}

.as_cart {
  position: relative;
}

.as_cart_box {
  position: absolute;
  top: 100%;
  right: 0;
  width: 300px;
  background-color: var(--dark-color2);
  z-index: 1;
  padding: 5px 20px 20px;
  box-shadow: 0 20px 20px rgb(255 255 255 / 5%);
  border-radius: 20px;
  margin-top: 20px;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  transition: all 0.3s linear;
}

.as_cart.cart_open .as_cart_box {
  opacity: 1;
  visibility: visible;
  margin-top: 10px;
}

.as_cart_list .as_cart_img {
  float: left;
}

.as_cart_list .as_cart_img img {
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.as_cart_info {
  width: calc(100% - 70px);
  float: right;
  padding-left: 10px;
}

.as_cart_list .as_cart_info a {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  margin-bottom: 5px;
  width: 110px;
  text-transform: capitalize;
}

.as_cart_list {
  border-bottom: 1px solid rgb(255 255 255 / 10%);
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
}

.as_cart_list li {
  width: 100%;
}

.as_cart_list .as_cart_info p {
  font-size: 14px;
}

.as_cart_box .as_cart_total,
.as_cart_box .as_cart_btn {
  padding-right: 20px;
}

.as_cart_box .as_cart_total p {
  margin-top: 10px;
  text-align: left;
  text-transform: capitalize;
}

.as_cart_box .as_cart_total p span {
  float: right;
  font-family: 'Inter', sans-serif;
}

.as_cart_box .as_cart_btn {
  text-align: center;
  padding-top: 5px;
}

.as_cart_box .as_cart_btn button {
  background-color: var(--dark-color1);
  padding: 0 5px;
  text-transform: capitalize;
}

.as_cart_box .as_cart_btn button::after {
  border-left-color: var(--dark-color1);
}

.as_cart_box .as_cart_btn button::before {
  border-right-color: var(--dark-color1);
}

.as_cart_box .as_cart_btn button:hover {
  background-color: var(--secondary-color);
}

.as_cart_box .as_cart_btn button:hover::after {
  border-left-color: var(--secondary-color);
}

.as_cart_box .as_cart_btn button:hover::before {
  border-right-color: var(--secondary-color);
}

.as_cart_box .as_cart_btn button:first-child {
  margin-right: 25px;
}

.as_cart_list::-webkit-scrollbar {
  width: 4px;
}

.as_cart_list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.as_cart_list::-webkit-scrollbar-thumb {
  background-color: #ff6f00;
  outline: #708090 solid 1px;
}

.as_menu > ul > li .as_submenu {
  position: absolute;
  left: 0;
  top: 100%;
  background-color: var(--dark-color2);
  padding: 15px 0;
  border-radius: 10px;
  width: 170px;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
}

.as_menu > ul > li > .as_submenu {
  transform: translateY(-10px);
}

.as_menu > ul > li:hover > .as_submenu {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.as_menu > ul > li .as_submenu li a {
  padding: 7px 20px;
  display: flex;
}

.as_menu > ul > li .as_submenu > li > .as_submenu {
  right: 100%;
  top: 8px;
  left: auto;
  transform: translateX(-20px);
}

.as_menu > ul > li .as_submenu > li:hover > .as_submenu {
  transform: translateX(-10px);
  opacity: 1;
  visibility: visible;
}

.as_menu > ul > li .as_submenu > li {
  position: relative;
}

.as_menu > ul > li .as_submenu:before {
  content: '';
  position: absolute;
  border-bottom: 7px solid var(--dark-color2);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  bottom: 100%;
  left: 13px;
}

.as_menu > ul > li > .as_submenu > li > .as_submenu:before {
  bottom: auto;
  top: 10px;
  left: 100%;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  border-left: 7px solid var(--dark-color2);
}

/* header css end */
/* banner css start */
.as_banner_wrapper {
  background-image: url(../public/images/back_banner.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 130px 310px;
  overflow: hidden;
  background-image: linear-gradient(150deg, #b2147b, #b24b14),
    url(../public/images/banner_background.png);
  background-blend-mode: overlay;
}

.as_banner_detail h5 {
  font-size: 26px;
  margin: 0 0 5px;
}

.as_banner_detail h1 {
  font-size: 54px;
  line-height: 62px;
  margin: 0 0 13px;
}

.as_banner_detail p {
  margin-bottom: 26px;
}

.as_banner_wrapper .slick-dots {
  text-align: center;
  display: none;
}

.as_banner_img img {
  animation: spin 9s infinite linear;
  -webkit-animation: spin 9s infinite linear;
  -moz-animation: spin 9s infinite linear;
  display: inline-block;
  width: 100%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

.as_banner_slider .slick-arrow.slick-prev {
  left: -265px;
}

.slick-arrow:hover {
  background-color: transparent !important;
}

.slick-arrow:hover span svg path {
  fill: #000000 !important;
}

.slick-arrow svg {
  width: 25px !important;
  height: 25px !important;
}

.slick-arrow {
  width: auto !important;
  padding: 0 !important;
  background: transparent;
}

.slick-arrow svg {
  width: 25px;
  height: 25px;
}

.slick-arrow {
  width: auto !important;
  padding: 0 !important;
  background: transparent;
}

.as_banner_slider .slick-arrow.slick-next {
  right: -900px;
}

.as_banner_wrapper .as_btn:hover {
  background-color: var(--dark-color2);
}

.as_banner_wrapper .as_btn:hover:after {
  border-left-color: var(--dark-color2);
}

.as_banner_wrapper .as_btn:hover:before {
  border-right-color: var(--dark-color2);
}

.as_banner_slider .slick-arrow:hover {
  background-color: var(--secondary-color);
}

.as_banner_slider .slick-arrow:hover:after {
  border-left-color: var(--secondary-color);
}

.as_banner_slider .slick-arrow:hover:before {
  border-right-color: var(--secondary-color);
}

/* banner css end */
/* about css start */
.as_about_wrapper {
  background: #f2f2f2;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #000000;
}

.as_about_wrapper p {
  margin-bottom: 32px;
}

.as_contact_expert {
  display: flex;
  background-color: #b2373b;
  border-radius: 10px;
  padding: 25px;
  margin-top: 40px;
  align-items: center;
  max-width: 470px;
}

.as_contact_expert .as_icon {
  width: 65px;
  height: 65px;
  background-color: var(--secondary-color);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  position: relative;
}

.as_contact_expert .as_icon:before {
  position: absolute;
  left: -7px;
  top: -7px;
  bottom: -7px;
  right: -7px;
  border: 1px dashed var(--secondary-color);
  content: '';
  border-radius: 100%;
}

.as_contact_expert:hover .as_icon:before {
  animation: spin 9s infinite linear;
  -webkit-animation: spin 9s infinite linear;
  -moz-animation: spin 9s infinite linear;
}

.as_contact_expert h5 {
  font-size: 18px;
  margin: 0 0 7px 0;
}

.as_contact_expert h1 {
  font-size: 34px;
  font-weight: 700;
  margin: 0 0 0 0;
}

.as_aboutimg {
  position: relative;
  z-index: 1;
  padding: 0 0 40px;
  text-align: right;
  overflow: hidden;
}

.as_aboutimg img {
  display: inline-block;
}

.as_aboutimg::after {
  position: absolute;
  content: '';
  top: 95px;
  right: 84px;
  background-color: #b2373b;
  width: 420px;
  height: 450px;
  z-index: -1;
}

.as_aboutimg::before {
  position: absolute;
  content: '';
  left: 1px;
  bottom: 0;
  border-left: 2px solid var(--secondary-color);
  border-bottom: 2px solid var(--secondary-color);
  width: 90px;
  height: 90px;
}

.as_about_slider {
  max-width: 520px;
  margin: 0 20px 0 auto;
}

.as_about_slider .slick-dots {
  position: absolute;
  left: -46px;
  bottom: 32px;
}

.as_about_slider .slick-dots li {
  display: flex;
  cursor: pointer;
  margin: 15px 0;
}

/* about css end */
/* service css start */

.as_service_wrapper {
  color: #000000;
  text-align: center;
}

.as_service_box {
  background-color: #ffffff;
  padding: 50px 22px;
  margin-top: 30px;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
}

.as_service_box .as_icon {
  width: 80px;
  background-color: #1d741f;
  height: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: relative;
  transition: all 0.3s linear;
}

.as_service_box .as_icon:after {
  border: 1px dashed #b2373b;
  content: '';
  position: absolute;
  left: -10px;
  top: -10px;
  bottom: -10px;
  right: -10px;
  border-radius: 100%;
}

.as_service_box:hover .as_icon {
  background-color: var(--secondary-color);
}

.as_service_box:hover .as_icon:after {
  border-color: var(--secondary-color);
  animation: spin 9s infinite linear;
  -webkit-animation: spin 9s infinite linear;
  -moz-animation: spin 9s infinite linear;
}

.as_service_box .as_subheading {
  /* margin: 30px 0 10px;
  padding-bottom: 16px;
  position: relative; */
  margin: 25px 0 10px;
  padding-bottom: 10px;
  position: relative;
}

.as_service_box .as_subheading:after {
  width: 70px;
  height: 2px;
  background-color: var(--secondary-color);
  position: absolute;
  content: '';
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.as_service_box p {
  font-size: 14px;
  line-height: 24px;
}

.as_link {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  border-bottom: 1px solid #b2373b;
  color: #b2373b;
  letter-spacing: 0.6px;
}

/* service css end */
/* zodiec sign css start */
/*.as_zodiac_sign_wrapper{
  background-image: url('../public/images/bg3.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position:center;
}*/

.as_zodiac_sign_wrapper {
  background: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #000000;
}

.as_sign_img img {
  margin: 0 auto;
}

.as_sign_ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.as_sign_ul li {
  margin-top: 40px;
}

.as_sign_box {
  background-color: #b2373b;
  max-width: 230px;
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.2s linear;
}

.as_sign_box > a {
  display: flex;
  align-items: center;
  color: #ffffff;
}

.as_sign_box .as_sign {
  background-color: #740410;
  height: 80px;
  width: 54px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 38px;
  position: relative;
  transition: all 0.2s linear;
}

.as_sign_box h5 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 2px;
}

.as_sign_box p {
  margin: 0;
  font-size: 14px;
  line-height: 24px;
}

.as_sign_box:after,
.as_sign_box:before {
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 20px solid #b2373b;
}

.as_sign_box:before {
  left: auto;
  right: 100%;
  border-left: none;
  border-right: 20px solid #b2373b;
}

.as_sign_box .as_sign:after,
.as_sign_box .as_sign:before {
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-left: 18px solid #740410;
}

.as_sign_box:hover {
  background-color: #e8ba22;
}

.as_sign_box:hover:after {
  border-left-color: #e8ba22;
}

.as_sign_box:hover:before {
  border-right-color: #e8ba22;
}

.as_sign_box:hover .as_sign {
  background-color: #b69320;
}

.as_sign_box:hover .as_sign:after {
  border-left-color: #b69320;
}

.as_sign_box:hover .as_sign:before {
  border-right-color: #b69320;
}

.as_sign_box .as_sign:before {
  left: auto;
  right: 100%;
  border-left: none;
  border-right: 18px solid #740410;
}

.as_sign_ul li:first-child,
.as_sign_ul li:last-child {
  transform: translateX(200px);
}

.as_sign_ul li:nth-child(2),
.as_sign_ul li:nth-child(5) {
  transform: translateX(100px);
  z-index: 1;
}

.as_sign_ul.as_sign_ul_right li:first-child,
.as_sign_ul.as_sign_ul_right li:last-child {
  transform: translateX(-200px);
}

.as_sign_ul.as_sign_ul_right li:nth-child(2),
.as_sign_ul.as_sign_ul_right li:nth-child(5) {
  transform: translateX(-100px);
}

.as_sign_img img {
  animation: spin 9s infinite linear;
  -webkit-animation: spin 9s infinite linear;
  -moz-animation: spin 9s infinite linear;
  width: 100%;
}

.as_sign_ul_right .as_sign_box {
  margin: 40px 0 0 auto;
}

/* zodiec sign css end */
/* zodiec sign form css start */

section.as_know_sign_wrapper {
  color: #000000;
}

.as_sign_form ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: flex-end;
}

.as_sign_form ul.react-datepicker__time-list {
  flex-wrap: wrap;
}

.react-datepicker__triangle {
  left: -40px !important;
}

.as_input_feild {
  position: relative;
}

.as_input_feild > span {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.as_sign_form ul li {
  max-width: 270px;
  width: 100%;
  padding: 0 15px;
}

.as_sign_form ul li:nth-child(3) {
  max-width: 370px;
}

.as_sign_form ul li:last-child {
  max-width: 205px;
}

.as_sign_form {
  padding: 25px 2px 50px;
  /* padding: 25px 33px 50px; */
  color: #ffffff;
  background-image: linear-gradient(150deg, #b2147b, #b24b14);
}

li.as_form_box {
  text-align: left;
}

/* zodiec sign form css end */
/* customer css start */
.as_customer_wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #ffffff;
  color: #000000;
}

.as_customer_slider .slick-dots {
  text-align: center;
}

.as_customer_box {
  background-color: #ffe2c6;
  padding: 0 45px 42px;
  position: relative;
  margin: 50px 15px;
  z-index: 1;
  color: #000000;
  font-weight: 500;
}

.as_customer_box:before {
  display: none;
  content: '';
  position: absolute;
  top: 75px;
  transform: translateX(-50%);
  left: 50%;
  width: 86px;
  height: 74px;
  background-image: url(../public/images/svg/quote2.svg);
  z-index: -1;
}

.as_customer_box .as_customer_img > img {
  border-radius: 100%;
  border: 4px solid #b2245d;
  width: 99px;
  height: 99px;
  object-fit: cover;
}

.as_customer_box .as_customer_img {
  position: relative;
  display: inline-flex;
  margin: -45px 0 34px;
}

.as_customer_box .as_customer_img span {
  position: absolute;
  width: 38px;
  height: 38px;
  background-color: #b2245d;
  border-radius: 100%;
  line-height: 32px;
  right: -10px;
  bottom: 0px;
}

.as_customer_box .as_customer_img span img {
  display: inline-block;
}

.as_customer_box p {
  font-size: 14px;
  line-height: 24px;
}

.as_customer_box h3 {
  font-size: 20px;
  margin: 15px 0 0;
}

/* customer css end */
/* why choose css start */

section.as_whychoose_wrapper {
  color: #000000;
  background: #ffffff;
  background-image: url(../public/images/why_choose_us.jpg);
  background-repeat: repeat;
  background-size: 60%;
  background-attachment: fixed;
}

section.as_whychoose_wrapper.as_padderTop80 {
  padding-top: 80px;
}

.as_whychoose_box {
  background-color: #ffffff;
  padding: 40px 22px;
  margin-top: 30px;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  min-height: 300px;
}

.as_whychoose_box .as_icon {
  width: 80px;
  background-color: #b2373b;
  height: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: relative;
  transition: all 0.3s linear;
}

.as_whychoose_box .as_icon:after {
  border: 1px dashed #b2373b;
  content: '';
  position: absolute;
  left: -10px;
  top: -10px;
  bottom: -10px;
  right: -10px;
  border-radius: 100%;
}

.as_whychoose_box .as_icon img {
  width: 50px;
}

.as_whychoose_box:hover .as_icon {
  background-color: var(--secondary-color);
}

.as_whychoose_box:hover .as_icon:after {
  border-color: var(--secondary-color);
  animation: spin 9s infinite linear;
  -webkit-animation: spin 9s infinite linear;
  -moz-animation: spin 9s infinite linear;
}

.as_whychoose_box .as_number > span {
  font-size: 24px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.as_whychoose_box h4 {
  font-size: 20px;
  margin: 25px 0 6px;
  text-align: center;
  transition: all 0.2s linear;
  font-weight: 600;
}

.as_number {
  position: relative;
  width: 130px;
  margin: 0 auto;
  height: 120px;
  display: flex;
  align-items: end;
}

.as_whychoose_box:hover h4 {
  color: #b2245d;
}

/* why choose css end */
/* blog css start */
.as_blog_wrapper {
  background: #f2f2f2;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #000000;
}

.as_blog_img {
  position: relative;
  margin-bottom: 36px;
}

.as_blog_img .as_btn {
  position: absolute;
  right: 35px;
  bottom: -25px;
  color: #fff;
  padding: 0 5px;
}

.as_blog_box {
  margin-top: 30px;
  text-align: initial;
  background: #ffffff;
  box-shadow: 0px 11px 12px rgb(220 212 212);
}

.as_blog_box:hover {
  box-shadow: none;
  border: 1px solid rgb(220 212 212);
}

.as_blog_img img {
  width: 100%;
}

.as_blog_box:hover .as_subheading {
  color: var(--secondary-color);
}

.as_blog_box ul {
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 0 15px;
}

.as_blog_box ul li {
  display: inline-flex;
  margin-right: 30px;
  font-size: 14px;
  line-height: 24px;
}

.as_blog_box ul li img {
  margin-right: 10px;
}

.as_blog_box .as_subheading {
  line-height: 32px;
  margin: 8px 0;
  transition: all 0.2s linear;
  padding: 0 15px;
}

.as_blog_box .as_font14 {
  font-size: 14px;
  line-height: 24px;
  padding: 12px;
  padding-top: 0;
}

.as_blog_box.as_blog_single .as_subheading {
  color: #e8ba22;
}

/* blog css end */
/* product css start */
.as_product_wrapper {
  color: #000000;
}

.as_product_box {
  text-align: left;
  margin: 30px 10px 0;
}

.as_product_img {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
}

.as_product_img ul {
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  bottom: -60px;
  left: 0;
  right: 0;
  background-color: var(--secondary-color);
  padding: 10px 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s linear;
  display: flex;
  justify-content: space-around;
}

.as_product_img > img {
  width: 100%;
}

.as_product_box:hover .as_product_img ul {
  bottom: 0;
  opacity: 1;
  visibility: visible;
}

.as_product_img ul li {
  display: inline-flex;
  line-height: 40px;
  color: #ffffff;
}

.as_product_img ul li:last-child {
  border-right: none;
}

.as_product_img ul li:nth-child(2) {
  position: relative;
}

.as_product_img ul li:nth-child(2):after {
  content: '';
  position: absolute;
  left: -30px;
  right: -30px;
  top: 0;
  bottom: 0;
  border-left: 1px solid #fda2658f;
  border-right: 1px solid #fda2658f;
}

.as_product_box .as_subheading {
  margin: 5px 0 6px;
}

.as_product_box .as_price {
  font-size: 18px;
  font-weight: 600;
}

.as_product_box .as_price > span {
  font-size: 14px;
  font-weight: 400;
}

.as_product_box .as_price > del {
  color: #5e7482;
}

.as_product_img ul li span {
  margin-left: 14px;
  font-size: 14px;
}

.as_product_box ul li img {
  display: inline-block;
}

.as_product_wrapper.as_product_page {
  background-color: var(--dark-color2);
}

/* product css end */
/* overview css start */

.as_overview_wrapper {
  background: #f2f2f2;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #000000;
}

.as_footer_logo img {
  width: 180px;
}

.as_overview_inner {
  padding: 42px 50px 50px;
  background-image: linear-gradient(134deg, #124361, #422d52);
  color: #fff;
}

.as_overview_inner h4 {
  font-size: 24px;
  margin: 0 0 11px;
}

.as_overview_inner .as_btn {
  margin-top: 10px;
  padding: 0 8px;
}

.as_overview_inner .as_btn img {
  margin-right: 10px;
}

/* overview css end */

/* footer css start */

.as_footer_wrapper {
  width: 100%;
  background: #020107;
}

.as_newsletter_wrapper {
  border-bottom: 1px solid rgb(255 255 255 / 10%);
}

.as_newsletter_box {
  position: relative;
}

.as_newsletter_box .form-control {
  border-radius: 0px;
  height: 60px;
  background: #ffffff;
  border-radius: 5px;
}

.as_newsletter_wrapper .row {
  align-items: center;
}

.as_newsletter_box:after,
.as_newsletter_box:before {
  display: none;
}

.as_newsletter_box:after {
  right: 100%;
  left: auto;
  border-left: none;
  border-right: 17px solid #ffffff;
}

.as_newsletter_wrapper {
  padding-bottom: 30px !important;
}

.as_footer_inner {
  padding-top: 30px !important;
}

.as_newsletter_box .as_btn {
  position: absolute;
  right: 5px;
  top: 5px;
  bottom: 5px;
  z-index: 1;
}

.as_footer_inner {
  display: flex;
}

.as_footer_widget {
  margin-top: 30px;
}

.as_footer_widget > p {
  margin: 18px 0 20px;
}

.as_footer_widget > ul > li:hover {
  color: var(--secondary-color);
}

.as_footer_widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

section.as_contact_section {
  padding-top: 120px;
}

.as_footer_widget > ul > li {
  padding-bottom: 10px;
  position: relative;
  font-size: 14px;
}

.as_footer_widget > ul > li span svg {
  fill: #ffffff;
  margin-right: 10px;
}

.as_footer_widget > ul > li:hover span svg path {
  fill: var(--secondary-color);
}

.as_footer_widget > ul > li:hover span {
  transition: none;
}

.as_footer_heading {
  font-size: 22px;
  line-height: 60px;
  margin: 0;
  position: relative;
  margin-bottom: 22px;
}

.as_footer_heading:after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 92px;
  height: 2px;
  background-color: var(--secondary-color);
}

.as_footer_widget .as_contact_list li {
  padding-left: 0;
  display: flex;
  align-items: baseline;
}

.as_footer_widget .as_contact_list li:before {
  display: none;
}

.as_footer_widget .as_contact_list li .as_contact_list_image {
  margin: 0 15px 0 0;
  transform: translateY(7px);
  width: 25px;
}

.as_footer_widget .as_contact_list li p {
  margin: 0;
}

.as_share_box ul li a {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #1baeaf;
  border-radius: 100%;
}

.as_share_box ul li a:hover {
  background-color: var(--secondary-color);
}

.as_share_box ul li {
  display: inline-flex;
  margin-right: 5px;
}

.as_copyright_wrapper {
  background-color: rgb(2 1 7 / 98%);
  padding: 13px 0 5px;
}

/* footer css end */
/* breadcrum start */
.as_breadcrum_wrapper {
  /* background-image: url('../public/images/bg7.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 25px 0 30px;
}

.as_breadcrum_wrapper h1 {
  margin: 0px 0 15px;
  font-size: 28px;
}

.as_breadcrum_wrapper .breadcrumb {
  display: inline-flex;
  background-color: rgb(255 255 255 / 10%);
  border-radius: 40px;
  font-size: 14px;
  line-height: 18px;
  padding: 11px 30px;
  margin: 0px;
}

.breadcrumb > li + li:before {
  content: '/\00a0/\00a0';
}

/* breadcrum end */
/* service detail start */
.as_service_page .as_service_wrapper {
  background-image: url(../public/images/bg6.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.as_service_page .as_service_wrapper .as_service_box {
  background-color: #031d2e;
}

.as_servicedetail_wrapper {
  background-color: #ffffff;
  color: #000000;
  padding-top: 120px;
}

.as_service_ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.as_service_ul li {
  padding: 0 0 6px 23px;
  position: relative;
}

.as_service_ul li:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: url(../public/images/svg/arrow.svg) no-repeat center;
  left: 0;
  top: 8px;
}

.as_service_detail_inner .as_heading {
  font-size: 32px;
  padding-bottom: 15px;
  margin: 22px 0 15px;
}

.as_service_detail_inner p {
  margin-bottom: 20px;
}

.as_service_detail_inner h4 {
  font-size: 24px;
}

.as_service_widget h3 {
  font-size: 22px;
  display: inline-flex;
  padding-bottom: 11px;
}

.as_service_widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.as_service_widget ul li {
  font-size: 14px;
  margin-bottom: 7px;
  padding-left: 24px;
  position: relative;
}

.as_service_widget ul li a {
  display: flex;
  justify-content: space-between;
}

.as_service_widget ul li a span {
  transition: none;
}

.as_service_widget ul li a span:first-child {
  border-bottom: 1px solid transparent;
}

.as_service_widget ul li:hover a span:first-child {
  border-color: var(--secondary-color);
}

.as_service_widget ul li:hover {
  color: var(--secondary-color);
}

.as_service_widget ul li:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: url(../public/images/svg/arrow.svg) no-repeat center;
  left: 0;
  top: 8px;
}

.as_service_widget ul li:hover:before {
  background: url(../public/images/svg/arrowHover.svg) no-repeat center;
}

.as_service_widget.as_download_box {
  background-color: var(--secondary-color);
  padding: 50px 10px;
}

.as_gplay {
  margin-bottom: 15px;
  display: inline-flex;
}

.as_service_widget .as_heading::after {
  width: 75%;
}

/* service detail End */
/* zodiac detail start */
.as_card_box img {
  border-radius: 10px;
}

.as_card_box > a {
  outline: none;
}

.as_zodiac_sidebar .as_sign_box {
  margin-bottom: 20px;
}

.as_zodiac_heading .as_sign {
  background-color: var(--secondary-color);
  height: 80px;
  width: 54px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 38px;
  position: relative;
}

.as_zodiac_heading .as_sign:after,
.as_zodiac_heading .as_sign:before {
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-left: 18px solid var(--secondary-color);
}

.as_zodiac_heading .as_sign:before {
  left: auto;
  right: 100%;
  border-left: none;
  border-right: 18px solid var(--secondary-color);
}

.as_zodiac_heading {
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  padding-left: 20px;
}

.as_zodiac_heading > span {
  font-size: 14px;
  font-family: 'Inter', sans-serif;
}

.as_tab_wrapper div#myTabContent {
  margin-top: 20px;
}

.as_tab_wrapper .nav-tabs > li > button {
  background-color: transparent !important;
  border: none !important;
  font-size: 14px;
  padding: 16px 35px;
  position: relative;
  color: #fff;
}

.as_tab_wrapper .nav-tabs > li > button::before {
  position: absolute;
  content: '';
  height: 4px;
  width: 0;
  background-color: var(--secondary-color);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.as_tab_wrapper .nav-tabs > li > button:hover,
.as_tab_wrapper .nav-tabs > li > button.active {
  color: var(--secondary-color);
}

.as_tab_wrapper .nav-tabs > li > button:hover:before,
.as_tab_wrapper .nav-tabs > li > button.active:before {
  width: 42px;
}

.as_tab_wrapper .nav-tabs {
  background-color: #10334a;
  border: none;
}

.as_tab_wrapper li {
  margin: 10px 0;
}

.as_countdown_wrapper ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.as_countdown_wrapper ul li {
  display: inline-flex;
  flex-direction: column;
  margin: 50px 0 30px;
  padding-right: 62px;
  position: relative;
}

.as_countdown_wrapper ul li .progress-percentage {
  width: 108px;
  height: 108px;
  border: 9px solid #193e57;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-family: 'Inter', sans-serif;
}

.as_countdown_wrapper ul li h3 {
  margin: 15px 0 0;
  text-transform: capitalize;
}

.as_countdown_wrapper ul li:last-child {
  padding-right: 0px;
}
.astrologer_heading h4 {
  font-size: 20px;
  display: flex;
  align-items: center;
}
.astrologer_feed_area .astrologer_heading h4 svg{
  cursor: pointer;
}

.as_zodiac_single_wrapper {
  background-color: #f2f2f2;
  color: #000000;
  margin-top: 73px;
}

/* .as_zodiac_single_wrapper .astrologer_heading {
  padding-top: 95px;
} */

.as_zodiac_single_wrapper .astrologer_type .nav .nav-item a {
  padding: 15px 15px;
  color: #767c90;
  font-size: 18px;
  cursor: pointer;
}

.as_zodiac_single_wrapper .astrologer_type .nav .nav-item a.active {
  color: #886459;
  border-bottom: 2px solid #886459;
  font-weight: 500;
}

.as_zodiac_single_wrapper .border_bottom {
  border-bottom: 1px solid #b9bbbd;
  padding-bottom: 0;
}

section.as_tarotReading_wrapper {
  color: #000000;
}

.as_progressbar {
  position: relative;
  width: 108px !important;
  height: 108px !important;
}

.as_countdown_wrapper ul li canvas {
  position: absolute;
  top: -21px;
  left: -21px;
}

/* zodiac detail End */
/* Blog Single Start */
.as_customer_box.as_bloquote {
  padding: 50px;
  margin-bottom: 20px;
}

.as_customer_box.as_bloquote h3 span {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

.as_tag_wrapper .as_btn {
  background-color: #124361;
  height: 40px;
  text-transform: capitalize;
  padding: 0 12px;
}

.as_tag_wrapper .as_btn:after,
.as_tag_wrapper .as_btn:before {
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 13px solid #124361;
}

.as_tag_wrapper .as_btn:before {
  border-left: none;
  border-right: 13px solid #124361;
}

.as_tag_wrapper ul li {
  margin-right: 17px;
  align-items: center;
}

.as_tag_wrapper ul {
  padding-top: 15px;
  border-top: 1px solid rgb(255 255 255 / 10%);
}

.as_about_admin {
  background-color: var(--secondary-color);
  display: flex;
  align-items: center;
  padding: 30px 40px;
}

.as_about_admin .as_aboutImage {
  width: 117px;
  margin-right: 32px;
}

.as_about_admin .as_aboutDetail {
  width: calc(100% - 149px);
}

.as_about_admin .as_aboutImage img {
  border: 3px solid #fff;
  border-radius: 100%;
}

.as_comment_section ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.as_comment_section ul li {
  display: flex;
}

.as_comment_section > ul > li {
  border-bottom: 1px solid rgb(255 255 255 / 10%);
  padding: 40px 0;
}

.as_comment_section > ul > li:last-child {
  border-bottom: none;
}

.as_comment_section > ul > li > ul > li {
  border-top: 1px solid rgb(255 255 255 / 10%);
  padding: 40px 0 0;
}

.as_comment_section > ul > li > ul > li:first-child {
  border: none;
  padding: 0 0 40px;
}

.as_comment_section > ul > li > ul > li {
  margin-left: 100px;
}

.as_comment_section > ul > li .as_comnt_img {
  width: 110px;
}

.as_comment_section > ul > li .as_comnt_img img {
  border-radius: 100%;
}

.as_comment_section > ul > li .as_comnt_detail {
  width: calc(100% - 110px);
  position: relative;
}

.as_comment_section > ul > li .as_comnt_detail .as_btn {
  position: absolute;
  right: 35px;
  top: 0;
  padding: 0 10px;
}

.as_comment_section > ul > li .as_comnt_detail .as_btn img {
  margin-right: 10px;
}

.as_comment_section > ul > li .as_comnt_detail > span {
  display: flex;
  padding: 5px 0 10px;
}

.as_comment_section > ul > li .as_comnt_detail > span img {
  margin-right: 10px;
}

.as_comment_form .as_heading,
.as_comment_section .as_heading {
  font-size: 32px;
}

.as_comment_form .form-control {
  margin-bottom: 30px;
  border-radius: 0px;
}

.as_comment_form textarea.form-control {
  height: 140px;
  padding: 15px 30px;
  resize: none;
}

.as_blog_box.as_blog_single {
  margin: 0px;
}

.as_search_widget {
  position: relative;
  margin-bottom: 50px;
}

.as_search_widget > a {
  position: absolute;
  background-color: var(--secondary-color);
  padding: 15px 18px;
  display: inline-flex;
  bottom: 0;
  top: 0;
  right: 0;
}

.as_search_widget .form-control {
  padding-right: 70px;
  border-radius: 0px;
}

.as_tag_wrapper > .as_btn {
  margin: 0 15px 10px;
}

.as_service_widget .as_recentposts li {
  padding: 0;
  margin: 5px 0 13px;
}

.as_service_widget .as_recentposts li h5 {
  margin: -5px 0 0;
  font-size: 16px;
  line-height: 26px;
  transition: none;
}

.as_service_widget .as_recentposts li:before {
  display: none;
}

.as_service_widget .as_recentposts li .as_img {
  margin-right: 15px;
}

.as_service_widget .as_recentposts li .as_detail > span {
  display: flex;
  align-items: center;
}

.as_service_widget .as_recentposts li .as_detail > span img {
  margin-right: 10px;
}

.as_tag_wrapper .as_btn:hover {
  background-color: var(--secondary-color);
}

.as_tag_wrapper .as_btn:hover:before {
  border-right-color: var(--secondary-color);
}

.as_tag_wrapper .as_btn:hover:after {
  border-left-color: var(--secondary-color);
}

.as_blog_box .as_subheading > a {
  transition: none;
}

/* Blog Single End */
/* Contact Start */
.as_info_box {
  display: flex;
  align-items: center;
}

.as_info_box .as_icon {
  width: 65px;
  background-color: var(--secondary-color);
  height: 65px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: relative;
  margin-right: 26px;
}

.as_info_box .as_icon:after {
  border: 1px dashed var(--secondary-color);
  content: '';
  position: absolute;
  left: -7px;
  top: -7px;
  bottom: -7px;
  right: -7px;
  border-radius: 100%;
}

.as_info_box:hover .as_icon:after {
  animation: spin 9s infinite linear;
  -webkit-animation: spin 9s infinite linear;
  -moz-animation: spin 9s infinite linear;
}

.as_info_box .as_info {
  width: calc(100% - 100px);
}

.as_info_box h5 {
  color: var(--secondary-color);
  font-size: 20px;
}

.as_contact_info .as_heading {
  font-size: 32px;
}

.as_contact_info > p {
  margin-bottom: 36px;
}

.as_contact_form {
  padding: 46px 50px 50px;
  background-color: #ffffff;
  margin-bottom: -263px;
  position: relative;
  color: #000000;
}

.as_contact_form .form-control {
  border: 1px solid rgb(100 100 100 / 27%);
  border-radius: 0px;
}

.as_contact_form label {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 7px;
}

.as_contact_form textarea.form-control {
  resize: none;
  height: 120px;
}

.as_contact_form .as_subheading {
  margin: 0 0 37px;
}

/* Contact End */
/* Search popup start */
.as_search_boxpopup {
  position: fixed;
  left: 0;
  top: 100%;
  bottom: 0;
  right: 0;
  background-color: rgb(3 29 46 / 80%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
}

.as_search_boxpopup.popup_open {
  top: 0;
  opacity: 1;
  visibility: visible;
}

.as_search_inner {
  max-width: 600px;
  width: 100%;
}

.as_search_inner .as_search_widget {
  margin: 0;
}

.as_cancel {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}

.as_cancel svg {
  fill: var(--primary-color);
}

.as_cancel:hover svg {
  fill: var(--secondary-color);
}

/* Search popup End */
/* Login popup Start */
.modal.show {
  opacity: 1;
  background-color: rgb(3 29 46 / 87%);
}

.modal .modal-dialog {
  margin: 0 auto;
  transform: none !important;
  /* width: 500px; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.modal-content {
  background-color: var(--dark-color2);
  width: 100%;
}

.modal-header {
  padding: 15px 25px;
  border-color: rgb(255 255 255 / 10%);
}

.modal-header h4 {
  font-size: 24px;
}

.modal-body .form-control {
  border: 1px solid rgb(255 255 255 / 10%);
}

.modal-body {
  padding: 40px;
}

.modal-header .close {
  color: #fff;
  font-weight: 300;
  text-shadow: none;
  opacity: 1;
  width: 25px;
  height: 25px;
  line-height: 18px;
  background-color: var(--dark-color1);
  border-radius: 100%;
  margin-top: 5px;
  font-size: 16px;
  outline: none !important;
}

.as_login_data {
  display: flex;
  justify-content: end;
  font-size: 14px;
  font-weight: 400;
}
.as_login_fgtPwd > p:hover {
  color: var(--secondary-color);
}

.as_login_data label {
  font-weight: 400;
  margin: 0;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  color: #767c90;
}

.as_login_data a {
  color: #767c90;
}

.as_login_data label input {
  display: none;
}

.as_login_data label .checkmark {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  background-color: #ffffff;
  border-radius: 4px;
  top: 4px;
  border: 1px solid #767c90;
}

.login_form p {
  color: #767c90;
}

.as_login_fgtPwd {
  cursor: pointer;
}

.as_login_data label .checkmark:after {
  content: '';
  position: absolute;
  width: 9px;
  height: 5px;
  border-left: 2px solid #00a5a7;
  border-bottom: 2px solid #00a5a7;
  transform: rotate(-45deg) scale(0);
  top: 5px;
  left: 4px;
}

.as_login_data label input:checked + .checkmark:after {
  transform: rotate(-45deg) scale(1);
}

.as_login_box,
.as_signup_box {
  display: none;
}

.as_login_box.active,
.as_signup_box.active {
  display: block;
}

/* Login popup End */
/* Appointment Page Start */
.as_appointment_wrapper,
.as_product_single_wrapper,
.as_team_wrapper,
.as_privacy_wrapper,
.as_faq_wrapper,
.as_contact_section {
  background-image: url(../public/images/banner_background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.as_journal_box_wrapper {
  background-color: var(--dark-color2);
  display: flex;
  padding: 40px;
  border-radius: 10px;
}

.as_journal_box_wrapper label {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 14px;
}

.as_journal_box_wrapper .form-control {
  border: 1px solid rgb(255 255 255 / 10%);
}

.as_faq_section .accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: var(--dark-color2);
  border-radius: 10px;
}

.as_faq_section .accordion-item:first-of-type .accordion-button {
  color: #ffffff;
  background-color: var(--dark-color2);
  border-radius: 10px;
  border: none;
}

.as_faq_section .accordion-item:first-of-type {
  background-color: var(--dark-color2);
  border-radius: 10px;
}

.as_faq_section .accordion-button {
  color: #ffffff;
  background-color: #17384e;
}

.as_faq_section .as_journal_box_wrapper textarea.form-control {
  height: 100px;
  resize: none;
  padding-top: 10px;
  border: none;
}

.as_faq_section .as_journal_box_wrapper .as_subheading {
  margin: 0 0 40px;
  font-size: 28px;
}

.as_faq_section .accordion-body {
  padding: 1rem 1.25rem;
  background: #17384e;
  border-radius: 5px;
}

.as_faq_section .accordion-item {
  background-color: #17384e;
  border: 1px solid rgb(23 56 78);
  margin-bottom: 10px;
}

.as_faq_section .accordion-button:not(.collapsed)::after {
  background-image: none;
  transform: rotate(-360deg);
  content: '-';
  font-size: 40px;
  line-height: 15px;
}

.as_faq_section .accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: '+';
  font-size: 30px;
  transition: transform 0.2s ease-in-out;
  line-height: 20px;
  background-image: none;
  transition: all 0.3s linear;
}

.as_faq_section .accordion-button:focus {
  border-color: #17384e;
  box-shadow: 0 0 0 0.25rem rgb(23 56 78);
}

.as_journal_box_wrapper .form-group {
  margin-bottom: 20px;
}

/* Appointment Page End */
/* Error Page Start */
.as_error_wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: var(--dark-color2);
}

.as_error_wrapper h1 {
  font-size: 150px;
  line-height: 200px;
}

.as_error_wrapper h2 {
  text-transform: capitalize;
  letter-spacing: 6px;
  font-size: 35px;
}

.as_error_wrapper p {
  font-size: 20px;
}

/* Error Page End */
/* FAQ Page Start */
.card {
  background-color: var(--dark-color2);
  border-radius: 10px;
}

.card button.btn.btn-link {
  color: var(--primary-color);
  font-size: 18px;
  padding: 15px 20px;
  text-decoration: none;
  outline: none;
  width: 100%;
  text-align: left;
  white-space: normal;
}

.card-body {
  padding: 20px;
  font-size: 14px;
  border-top: 1px solid rgb(255 255 255 / 10%);
}

.card-header h2 {
  margin-bottom: 0;
}

/* FAQ Page End */
/* Team Page Start */
.as_team_box .as_share_box ul {
  padding: 0;
}

.as_team_box .as_team_img {
  border: 1px solid transparent;
  display: inline-flex;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.3s linear;
}

.as_team_box .as_team_img img {
  border-radius: 5px;
  transition: all 0.3s linear;
}

.as_team_box:hover .as_team_img img,
.as_team_box:hover .as_team_img {
  border-radius: 100%;
}

.as_team_box:hover .as_team_img {
  border: 1px solid rgb(255 255 255 / 10%);
}

.as_team_box .as_subheading {
  margin-bottom: 4px;
}

.as_team_box {
  margin-bottom: 30px;
}

/* Team Page End */
/* shop single */
.as_shopsingle_wrapper,
.as_cartsingle_wrapper,
.as_checkout_wrapper {
  /* background-image: url('../public/images/bg4.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.as_shopsingle_nav .as_prod_img {
  margin: 20px 5px 0;
  cursor: pointer;
}

.as_shopsingle_for .as_prod_img img {
  margin: 0 auto;
}

.as_price {
  font-size: 28px;
  margin: 5px 0 15px;
  color: var(--secondary-color);
}

.as_price del {
  color: rgb(97 117 130);
  margin-left: 10px;
}

.prod_quantity {
  display: flex;
  align-items: center;
}

.prod_quantity input {
  max-width: 180px;
  margin: 0;
  padding: 0 60px;
  text-align: center;
}

.quantity {
  position: relative;
  margin-left: 30px;
  border: 1px solid rgb(255 255 255 / 0.1);
  width: 150px;
}

.quantity .qty_button {
  position: absolute;
  background-color: var(--dark-color2);
  border: none;
  right: 0;
  height: 50px;
  width: 50px;
  top: 0;
  border-left: 1px solid rgb(255 255 255 / 0.1);
  outline: none;
  color: #fff;
}

.quantity .qty_button.minus {
  left: 0;
  border-left: none;
  border-right: 1px solid rgb(255 255 255 / 0.1);
}

.product_buy .as_btn {
  margin-right: 25px;
}

.as_shopsingle_nav .slick-list.draggable {
  padding: 0 !important;
}

textarea.form-control {
  height: 120px;
  resize: none;
  padding-top: 15px;
}

.as_cartsingle_wrapper .prod_thumb {
  position: absolute;
}

.as_cartsingle_wrapper .prod_thumb img {
  width: 50px;
}

.as_cartsingle_wrapper .product_details {
  padding: 0 0 0 70px;
}

.as_cartsingle_wrapper td,
.as_cartsingle_wrapper th {
  padding: 20px !important;
  border-top: 1px solid rgb(255 255 255 / 10%) !important;
}

.as_cartsingle_wrapper table {
  border: 1px solid rgb(255 255 255 / 10%);
  color: #fff;
}

.cupon_code_wrap {
  display: flex;
}

.cupon_code_wrap .form-control {
  width: 35%;
  margin-right: 25px;
}

.checkout_wrapper_box ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}

.checkout_wrapper_box ul li {
  display: flex;
}

.checkout_wrapper_box ul li {
  display: inline-flex;
  margin: 0 20px 30px;
  background-color: var(--dark-color1);
}

.checkout_wrapper_box ul li:after {
  border-left-color: var(--dark-color1);
}

.checkout_wrapper_box ul li:before {
  border-right-color: var(--dark-color1);
}

.checkout_wrapper_box ul li.active {
  background-color: var(--secondary-color);
}

.checkout_wrapper_box ul li.active:after {
  border-left-color: var(--secondary-color);
}

.checkout_wrapper_box ul li.active:before {
  border-right-color: var(--secondary-color);
}

.checkout_wrapper_box {
  text-align: center;
  background-color: var(--dark-color2);
  padding: 40px;
  border-radius: 10px;
}

.checkout_wrapper_box .form-control {
  border: 1px solid rgb(255 255 255 / 10%);
}

.checkout_wrapper_box .step:not(:first-of-type) {
  display: none;
}

.step .receipt_btn {
  margin: 10px 25px;
}

.stock_details span {
  color: #1cb71c;
}

.datepicker--time-current-hours.-focus-:after,
.datepicker--time-current-minutes.-focus-:after {
  background-color: var(--secondary-color) !important;
}


.item-set-bt {
  display: flex;
  width: 100%;
  column-gap: 15px;
}
.item-set {
  column-gap: 50px;
  flex-direction: column;
}

.item-set h4 {
  font-size: 16px;
  display: flex;
  align-items: center;
}

/* Responsive css */
@media (min-width: 1400px) and (max-width: 1810px) {
  /* .as_logo {
    padding: 8px 40px;
  } */

  .as_header_detail {
    width: calc(100% - 220px);
  }

  .as_info_detail {
    padding-right: 20px;
  }

  .as_menu_wrapper {
    padding-right: 50px;
  }

  .as_banner_slider .slick-arrow.slick-next {
    right: -795px;
  }

  .as_info_detail ul li,
  .as_right_info,
  .as_menu > ul > li {
    font-size: 14px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .as_banner_img img {
    width: 75%;
  }
}

@media (max-width: 1610px) {
  .as_banner_slider .slick-arrow.slick-next {
    right: -730px;
  }

  .as_customer_slider .slick-arrow.slick-prev {
    left: -100px;
  }

  .as_customer_slider .slick-arrow.slick-next {
    right: -80px;
  }
}

@media (max-width: 1450px) {
  .as_banner_slider .slick-arrow.slick-next {
    right: -700px;
  }

  .as_customer_slider .slick-arrow.slick-prev {
    display: none !important;
  }

  .as_customer_slider .slick-arrow.slick-next {
    display: none !important;
  }
}

@media (max-width: 1399px) {
  .as_header_detail {
    width: calc(100% - 220px);
  }

  .as_banner_wrapper {
    padding: 80px 100px;
  }

  .as_customer_box {
    padding: 0 40px 42px;
  }

  /* .as_info_detail,
  .as_menu_wrapper {
    padding-right: 35px;
  } */
  top
  .as_info_detail ul li {
    padding: 15px;
  }

  .as_infobox {
    font-size: 14px;
  }

  .as_menu > ul > li {
    margin: 0 10px;
  }

  .slick-arrow.slick-prev,
  .as_banner_slider .slick-arrow.slick-prev {
    left: -110px;
  }

  .slick-arrow.slick-next {
    right: -100px;
  }

  .as_banner_slider .slick-arrow.slick-next {
    right: -640px;
  }

  .astrologer_box .content_box {
    padding: 10px;
  }

  .astrologer_box ul.review_box li {
    color: #767c90;
    font-size: 13px;
    margin-right: 6px;
  }
}

@media (max-width: 1360px) {
  .as_menu ul li a {
    font-size: 13px;
  }

  .as_infobox {
    font-size: 12px;
  }

  .as_overview_slider button.slick-prev.as_btn.slick-arrow,
  .as_overview_slider button.slick-next.as_btn.slick-arrow {
    display: none !important;
  }
}

@media (max-width: 1260px) {
  .as_infobox .as_infoicon {
    width: 40px;
    height: 40px;
  }

  .as_cart_wrapper {
    margin-left: 20px;
    padding: 10px 15px;
    font-size: 11px;
  }

  .as_menu ul li a {
    padding: 13px 0;
  }

  .as_search_wrapper {
    padding-top: 14px;
  }

  .as_logo {
    padding:8px 30px;
  }
  .as_header_wrapper .as_logo img {
    width: 110px;
}
  .as_info_detail ul li {
    padding: 12px;
  }

  .as_info_detail,
  .as_menu_wrapper {
    padding-right: 0;
  }

  .as_banner_slider .slick-arrow.slick-next {
    right: -580px;
  }

  .as_customer_wrapper .slick-prev {
    left: 0;
  }

  .as_customer_wrapper .slick-next {
    right: 20px;
    margin: 0;
  }

  .as_overview_wrapper .slick-prev {
    left: -20px;
  }

  .as_overview_wrapper .slick-next {
    right: -4px;
  }
}

@media (max-width: 1199px) {
  .as_info_box {
    margin: 10px 0;
  }

  .menu_open ~ span.as_body_overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(2 23 37 / 80%);
    z-index: 0;
  }

  .as_search_wrapper {
    padding-top: 0;
  }

  .as_sign_box {
    margin: 40;
    margin: 40px auto 0 !important;
  }

  .as_toggle {
    display: flex;
    margin-right: 20px;
    cursor: pointer;
  }

  .as_menu_wrapper {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .as_menu ul li,
  .as_menu ul li a {
    display: block;
  }

  .as_menu {
    position: fixed;
    right: -200px;
    top: 0;
    bottom: 0;
    background-color: var(--dark-color1);
    z-index: 999;
    width: 200px;
    border-left: 2px solid var(--secondary-color);
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    transition: all 0.3s linear;
  }

  .menu_open .as_menu {
    right: 0;
    opacity: 1;
    visibility: visible;
  }

  .as_menu li {
    display: flex;
    border-bottom: 1px solid rgb(255 255 255 / 10%);
    margin: 0;
  }

  .as_menu ul li a:before {
    display: none;
  }

  .as_menu_wrapper {
    padding: 12px 10px;
  }

  .as_right_info {
    padding-left: 5px;
  }

  .as_search_inner {
    padding: 0 20px;
  }

  .as_menu > ul > li .as_submenu {
    position: relative;
    opacity: 1;
    visibility: visible;
    top: auto !important;
    left: auto !important;
    transform: none !important;
    widttoph: 100%;
    right: auto !important;
    padding: 0;
    background-color: transparent;
    display: none;
  }

  .as_menu > ul > li .as_submenu .as_submenu {
    display: none;
  }

  .as_menu > ul > li .as_submenu:before {
    display: none;
  }

  .as_menu ul li .as_submenu.active {
    display: block;
  }

  .as_metopnu ul li.as_submenu_li:after {
    position: absolute;
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #ffffff;
    top: 25px;
    right: 0;
  }

  .as_info {
    margin: 10px 0;
  }

  .as_banner_detail h1 {
    font-size: 44px;
    line-height: 50px;
  }

  .as_aboutimg::after {
    display: none;
  }

  .as_aboutimg::before {
    display: none;
  }

  .as_about_slider .slick-dots {
    position: absolute;
    left: 0;
    bottom: -15px;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .as_about_slider .slick-dots li {
    margin: 15px 5px;
    display: inline-block;
  }

  p {
    font-size: 14px;
    line-height: 24px;
  }

  .as_product_img > img {
    width: 100%;
  }

  .as_tab_wrapper .nav-tabs > li > a {
    padding: 16px 20px;
  }

  .as_countdown_wrapper ul li {
    padding-right: 30px;
  }

  .as_tag_wrapper .as_btn {
    margin-bottom: 10px;
  }

  .as_contact_form {
    margin-top: 50px;
  }

  .astrologer_box ul.review_box li {
    font-size: 15px;
  }
}

@media (max-width: 1160px) {
  .as_banner_slider .slick-arrow.slick-next {
    right: -545px;
  }
}

@media (min-width: 1145px) {
  .as_banner_wrapper .slick-dots {
    display: none !important;
  }
}

@media (max-width: 1145px) {
  .as_info_detail ul li .as_infobox > span.as_orange {
    width: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .as_banner_slider .slick-arrow.slick-next,
  .slick-arrow.slick-prev,
  .as_banner_slider .slick-arrow.slick-prev,
  .as_overview_wrapper .slick-next {
    display: none !important;
  }

  .as_banner_wrapper .slick-dots {
    text-align: left;
    display: none;
    position: absolute;
    left: -80px;
    transform: rotate(90deg);
    bottom: 0;
  }
}

@media (max-width: 991px) {
  .as_about_slider {
    max-width: 100%;
    margin: auto;
  }

  .as_contact_form {
    margin-bottom: 0;
    margin-top: 50px;
  }

  .as_product_description {
    padding-top: 50px;
  }

  .as_infobox .as_infoicon {
    width: 30px;
    height: 30px;
  }

  .as_infobox .as_infoicon img {
    width: 15px;
  }

  .as_info_detail ul li .as_infobox > span.as_orange {
    width: 40px;
  }

  .as_logo {
    padding: 4px 15px;
}
  .as_header_wrapper .as_logo img {
    width: 105px;
}

  .as_cart_wrapper {
    margin-left: 10px;
  }

  .as_infobox .as_infoicon {
    margin-right: 5px;
  }

  .as_cart_wrapper > span {
    margin-right: 12px;
  }

  .as_header_detail {
    width: calc(100% - 200px);
  }

  .as_info_detail,
  .as_menu_wrapper {
    padding-right: 6px;
  }

  .as_info_detail ul li {
    padding: 12px 5px;
  }

  .as_info_detail ul li .as_infobox > span.as_orange {
    width: 34px;
  }

  .as_infobox .as_logintext {
    display: none;
  }

  .as_banner_detail h1 {
    font-size: 30px;
    line-height: 40px;
  }

  .as_banner_slider .slick-arrow.slick-next {
    right: -450px;
  }

  .as_banner_detail h5 {
    font-size: 18px;
  }

  .as_aboutimg::before,
  .as_aboutimg::after {
    display: none;
  }

  .as_aboutimg img {
    margin: 0 20px 30px auto;
    width: 100%;
  }

  .as_padderBottom80 {
    padding-bottom: 70px;
  }

  .as_padderTop80 {
    padding-top: 70px;
    overflow: hidden;
  }

  .as_sign_ul li:first-child,
  .as_sign_ul li:last-child,
  .as_sign_ul li:nth-child(2),
  .as_sign_ul li:nth-child(5),
  .as_sign_ul.as_sign_ul_right li:first-child,
  .as_sign_ul.as_sign_ul_right li:last-child,
  .as_sign_ul.as_sign_ul_right li:nth-child(2),
  .as_sign_ul.as_sign_ul_right li:nth-child(5) {
    transform: translateX(0);
  }

  .as_sign_form ul {
    flex-direction: column;
  }

  .as_sign_box > div {
    width: calc(100% - 80px);
  }

  .as_sign_form ul li {
    max-width: 100%;
    width: 100%;
  }

  .as_sign_form ul li:nth-child(3) {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .as_sign_form ul li:last-child {
    max-width: 100%;
    text-align: center;
  }

  .as_newsletter_wrapper {
    display: block;
    text-align: center;
    float: left;
    width: 100%;
  }

  .as_newsletter_wrapper .as_heading::after {
    left: 50%;
    transform: translateX(-50%);
  }

  .as_newsletter_wrapper p {
    margin-bottom: 30px;
  }

  .as_footer_inner {
    float: left;
    display: block;
    width: 100%;
    margin-bottom: 0;
    padding-top: 20px;
  }

  .as_product_img ul {
    display: flex;
    justify-content: space-around;
  }

  .as_whychoose_wrapper .as_verticle_center {
    display: block;
    text-align: center;
  }

  .as_whychoose_wrapper .as_heading:after {
    left: 50%;
    transform: translateX(-50%);
  }

  .as_whychoose_wrapper p {
    margin-bottom: 30px;
  }

  .as_service_detail_inner {
    margin-bottom: 50px;
  }

  .as_newsletter_box {
    margin: 0 20px;
  }

  .as_card_box {
    text-align: center;
  }

  .as_card_box img {
    display: inline-block;
  }

  .as_service_widget.as_download_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .as_service_widget.as_download_box h3,
  .as_service_widget.as_download_box .as_gplay {
    display: inline-block;
  }

  .as_comment_form {
    margin-bottom: 50px;
  }

  .as_heading {
    font-size: 30px;
  }

  .as_about_slider .slick-dots {
    position: absolute;
    left: -45px;
    bottom: 55px;
    display: block;
  }

  .as_sign_box {
    max-width: 88%;
  }

  .as_sign_img {
    margin-top: 45px;
  }

  .as_blog_wrapper .row {
    justify-content: center;
  }

  .astrologer_box ul.review_box li {
    font-size: 11px;
  }

  .astrologer_box .content_box p {
    font-size: 13px !important;
  }

  .border_bottom {
    border-bottom: 1px solid #b9bbbd;
    padding-bottom: 14px !important;
    flex-direction: column-reverse;
  }

  .astrologer_area .search_box {
    position: relative;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .profile_setting_inner .profile_box {
    padding: 0 15px !important;
    height: 100%;
    padding-right: 30px;
    padding-bottom: 30px !important;
  }
}

@media (max-width: 895px) {
}

@media (max-width: 830px) {
  .as_banner_slider .slick-arrow.slick-next {
    right: -360px;
  }
}

@media (max-width: 767px) {
  .as_login_area .login_inner {
    width: 90%;
    margin: auto;
    padding: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgb(255 255 255 / 20%);
    background: #ffffff;
  }

  .as_login_area .login_form {
    padding: 0;
  }

  .as_info_box {
    margin: 10px 0;
  }

  .menu_open ~ span.as_body_overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(3 29 46 / 0%);
    z-index: 0;
  }

  .as_verticle_center {
    display: block;
  }

  .as_search_wrapper {
    padding-top: 0;
  }

  .as_sign_box {
    margin: 40;
    margin: 40px auto 0 !important;
  }

  .as_blog_img img {
    width: 100%;
  }

  .as_banner_slider .slick-arrow.slick-next {
    right: -75px;
  }

  .as_toggle {
    display: flex;
    margin-right: 20px;
    cursor: pointer;
  }

  .as_menu_wrapper {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .as_menu ul li,
  .as_menu ul li a {
    display: block;
  }

  .as_menu {
    position: fixed;
    left: -200px;
    top: 0;
    bottom: 0;
    background-color: var(--dark-color1);
    z-index: 999;
    width: 200px;
    border-right: 2px solid var(--secondary-color);
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    transition: all 0.3s linear;
  }

  .menu_open .as_menu {
    left: 0;
    opacity: 1;
    visibility: visible;
  }

  .as_menu li {
    display: flex;
    border-bottom: 1px solid rgb(255 255 255 / 10%);
    margin: 0;
  }

  .as_menu ul li a:before {
    display: none;
  }

  .as_menu_wrapper {
    padding: 12px 10px;
  }

  .as_cart_wrapper {
    display: flex;
  }

  .as_cart_wrapper > span {
    margin-right: 20px;
  }

  .slick-arrow.slick-prev,
  .as_banner_slider .slick-arrow.slick-prev {
    left: -102px;
  }

  .as_info_detail {
    justify-content: center;
    flex-direction: column;
    padding: 10px;
  }

  .as_right_info {
    padding-left: 5px;
  }

  .as_search_inner {
    padding: 0 20px;
  }

  .as_menu > ul > li .as_submenu {
    position: relative;
    opacity: 1;
    visibility: visible;
    top: auto !important;
    left: auto !important;
    transform: none !important;
    width: 100%;
    right: auto !important;
    padding: 0;
    background-color: transparent;
    display: none;
  }

  .as_menu > ul > li .as_submenu .as_submenu {
    display: none;
  }

  .as_menu > ul > li .as_submenu:before {
    display: none;
  }

  .as_menu ul li .as_submenu.active {
    display: block;
  }

  .as_menu ul li.as_submenu_li:after {
    position: absolute;
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #ffffff;
    top: 25px;
    right: 0;
  }

  .as_menu ul li.as_submenu_li ul li.as_submenu_li:after {
    top: 18px;
  }

  .as_banner_img {
    margin-top: 50px;
  }

  .wallet_area .recharge_area {
    margin-top: 30px;
  }
section.astrologer_area {
    margin-top: 45px;
}

}

@media (max-width: 650px) {
  .as_about_slider .slick-dots {
    position: absolute;
    left: 0;
    bottom: 15px;
    right: 0;
    display: flex;
    justify-content: center;
  }

  .as_logo {
    padding: 4px 13px;
  }
}

@media (max-width: 600px) {
  .as_comment_section > ul > li .as_comnt_detail .as_btn {
    position: relative;
    right: auto;
    top: auto;
    padding: 0 20px;
    margin: 10px 0 0 0;
  }

  .slick-arrow.slick-prev,
  .as_banner_slider .slick-arrow.slick-prev,
  .as_banner_slider .slick-arrow.slick-next {
    display: none !important;
  }

  .as_banner_wrapper {
    padding: 80px 40px;
    overflow: hidden;
  }

  .as_header_detail {
    width: calc(100% - 0px);
  }

  .as_banner_wrapper .slick-dots {
    text-align: center;
    display: none;
    position: relative;
    right: 0;
    left: 0;
    transform: rotate(0deg);
    bottom: 0;
  }

  .as_banner_wrapper .slick-initialized .slick-slide {
    text-align: center;
  }

  .as_comment_section > ul > li > ul > li {
    margin-left: 40px;
  }
}

@media (max-width: 567px) {
  .astrologer_type ul li a {
    padding: 12px 8px !important;
    font-size: 14px;
  }

  .border_bottom {
    border-bottom: 1px solid #b9bbbd;
    padding-bottom: 9px !important;
    flex-direction: column-reverse;
  }

  .as_btn {
    margin: 0;
  }

  .modal .modal-dialog {
    width: 100%;
  }

  .as_zodiac_heading {
    font-size: 24px;
  }

  .as_overview_inner {
    padding: 30px 30px 40px;
  }

  .as_cart_box {
    right: -30px;
  }

  .profile_inner_text ul li {
    display: block !important;
    align-items: center;
    margin-bottom: 15px;
  }

  .profile_inner_text ul li .last span:first-child {
    display: none !important;
  }
}

@media (max-width: 450px) {
  .as_newsletter_box {
    position: initial;
  }

  .kundli_form .generate_kudali_form .form-group label {
    font-size: 13px;
  }

  .as_about_admin {
    display: block;
  }

  .as_about_admin .as_aboutImage {
    width: 100%;
    margin-right: 0;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .as_about_admin .as_aboutDetail {
    width: calc(100% - 0px);
    text-align: center;
    padding-top: 10px;
  }

  .as_newsletter_box .as_btn {
    position: relative;
    right: 0;
    top: 20px;
    bottom: 0;
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .as_btn:after,
  .as_btn:before {
    display: none;
  }

  .as_journal_box_wrapper {
    padding: 40px 18px !important;
  }

  .as_banner_wrapper {
    padding: 80px 0px;
  }

  .as_banner_wrapper .slick-dots {
    text-align: center;
    display: block;
  }

  .as_info_detail ul li .as_infobox > span.as_orange {
    display: none;
  }

  .as_contact_expert h1 {
    font-size: 20px;
  }

  .as_contact_expert h5 {
    font-size: 15px;
  }

  .as_contact_expert .as_icon {
    width: 50px;
    height: 50px;
  }

  .as_zodiac_heading .as_sign {
    margin-right: 20px;
  }

  .as_zodiac_heading {
    font-size: 20px;
    padding-left: 10px;
  }

  .as_newsletter_box:after,
  .as_newsletter_box:before {
    display: none;
  }
}

@media (max-width: 400px) {
  .kundli_form .generate_kudali_form .form-group label {
    font-size: 11px;
  }

  .as_logo img {
    width: 250px;
  }
  .as_cart_box {
    width: 270px;
  }

  .modal .modal-dialog {
    margin: 50px auto;
  }

  .as_zodiac_heading {
    font-size: 16px;
  }

  .as_zodiac_heading .as_sign:after,
  .as_zodiac_heading .as_sign:before {
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }

  .as_zodiac_heading .as_sign {
    height: 60px;
    width: 35px;
  }

  .as_zodiac_heading > span {
    font-size: 12px;
  }

  .as_comment_section > ul > li > ul > li {
    margin-left: 20px;
  }
}

/*astrologer_box*/

section.astrologer_area {
  /* padding-top: 60px; */
  margin-top: 73px;
  color: #000000;
}

.astrologer_area .search_box {
  position: relative;
}

.astrologer_heading {
  background: #ffffff;
  padding: 15px 0;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.astrologer_area .search_box .form-control {
  background: #ffffff;
  color: #000000;
  height: 45px;
  padding: 0 10px;
  border: 1px solid #b3b3b3;
}

.astrologer_area .search_box button {
  position: absolute;
  right: 12px;
  top: 9px;
  color: #e8ba22;
}

.astrologer_area .astrologer_type ul li {
  color: #767c90;
  font-size: 18px;
}

.astrologer_type ul li a {
  padding: 15px 15px;
}

.astrologer_box {
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;
}

.astrologer_type li.active a {
  color: #886459;
  border-bottom: 2px solid #886459;
  font-weight: 500;
}

.border_bottom {
  border-bottom: 1px solid #b9bbbd;
  padding-bottom: 5px;
}

.astrologer_box .img_box img {
  width: 130px;
  height: 180px;
  object-fit: cover;
}

.astrologer_box .content_box {
  padding: 15px;
}

.astrologer_box ul.review_box {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.astrologer_box .content_box h4 {
  color: #040c29;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}

.astrologer_box .content_box h4 a:hover {
  color: #970625;
}

.astrologer_box ul.review_box li {
  color: #767c90;
  margin-right: 10px;
}

.astrologer_box .review_box .rating .full {
  color: #de8f40;
}

.astrologer_box .review_box .rating span {
  color: #cecfd0;
}

.astrologer_box .content_box p {
  color: #886459;
  margin-bottom: 0;
  font-size: 15px;
}

.astrologer_box .content_box button {
  border: 1px solid #b24b14;
  color: #b24b14;
  font-size: 15px;
  padding: 2px 15px !important;
  margin-top: 10px;
}

/*astrologer_close*/

/*astrologe_details*/

.astrologer_details_box .info_box {
  border-bottom: 1px solid #dadada;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.astrologer_details_box .info_box h4 {
  color: #040c29;
  font-size: 22px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}

.astrologer_details_box .info_box p {
  color: #767c90;
  font-size: 16px;
}

.astrologer_details_box .info_box ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.astrologer_details_box .info_box p {
  color: #767c90;
  font-size: 16px;
  margin-bottom: 0;
}

.astrologer_details_box .info_box ul li {
  color: #b24b14;
  margin-right: 25px;
  padding: 3px 0;
}

.astrologer_details_box .language_box {
  border-bottom: 1px solid #dadada;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.astrologer_details_box .language_box h4 {
  color: #979ba7;
  font-size: 18px;
  font-family: 'Inter', sans-serif;
  margin-bottom: 0;
}

.astrologer_details_box .language_box p {
  font-size: 16px;
  color: #040c29;
  margin-bottom: 0;
}

.astrologer_details_box .language_box.about {
  border: none;
  padding: 0;
  margin: 0;
}

.astrologer_img_box img {
  width: 100%;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
  height: 460px;
}

.astrologer_img_box .astro_contect {
  display: flex;
  align-items: center;
  width: 100%;
  background: #e8ba22;
}

span.mr-2 {
  margin-right: 15px;
}

.astrologer_detail .astrologer_heading {
  background: #ffffff;
  padding: 20px 0;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  padding-top: 40px;
}

.astrologer_img_box .astro_contect a {
  width: 50%;
  text-align: center;
  padding: 15px 1px;
  color: #000000;
  font-size: 18px;
}

.astrologer_img_box .astro_contect .talk {
  position: relative;
}

.astrologer_img_box .astro_contect a.talk:after {
  content: '';
  position: absolute;
  width: 2px;
  height: 40px;
  background: black;
  right: -2px;
  top: 11px;
}

.astrologer_img_box .astro_contect span {
  width: 30px;
  height: 30px;
  display: inline-block;
  border: 2px solid #000000;
  border-radius: 8px;
}

.astrologer_details_inner .gellary_area {
  margin-top: 30px;
}

.astrologer_details_inner .gellary_area h4 {
  color: #000000;
  font-size: 22px;
  font-family: 'Inter', sans-serif;
  margin-bottom: 14px;
  font-weight: 600;
}

.astrologer_details_inner .gellary_area .col-lg-3 {
  margin-bottom: 30px;
}

.astrologer_details_inner .gellary_area img {
  height: 180px;
  object-fit: cover;
}

.kundli_form .form-group label {
  font-size: 16px;
  color: #979ba7;
  margin-bottom: 8px;
}

.kundli_form .form-group {
  text-align: initial;
  margin: 10px 0;
  margin-bottom: 25px;
}

.zender_box [type='radio']:checked,
.zender_box [type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

.zender_box [type='radio']:checked + label,
.zender_box [type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.zender_box [type='radio']:checked + label:before,
.zender_box [type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  border: 1px solid #b24b14;
  border-radius: 100%;
  background: #fff;
}

.zender_box [type='radio']:checked + label:after,
.zender_box [type='radio']:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #b24b14;
  position: absolute;
  top: 6px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.zender_box [type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.zender_box [type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.zender_box {
  margin: 10px 0;
  margin-bottom: 25px;
}

.zender_box h4 {
  font-size: 16px;
  color: #979ba7;
  margin-bottom: 8px;
  font-family: 'Inter', sans-serif;
}

.zender_box .radio-buttons {
  display: flex;
  align-items: center;
}

.zender_box .radio-buttons p {
  margin-right: 15px;
}

.kundli_form h3 {
  font-size: 22px;
  color: #b24b14;
  font-family: 'Inter', sans-serif;
}

.astrologer_heading h1 {
  color: #4b3b5a;
  margin-bottom: 0;
  font-size: 24px;
}

.match_making_form .form-group {
  text-align: initial;
  margin: 10px 0;
  margin-bottom: 20px;
}

.match_making_form .zender_box {
  margin: 10px 0;
  margin-bottom: 0;
}

.match_making_form .col-lg-6 > h4 {
  color: #b24b14;
  font-family: 'Inter', sans-serif;
  font-size: 22px;
}

/*festival_css*/
.as_festival_box {
  background: #ffffff;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  margin-bottom: 30px;
}

.as_festival_box .cal_icon {
  margin-right: 5px;
}

.as_festival_box .day {
  margin-left: 5px;
}

.as_festival_box .img_box img {
  max-width: 100%;
  width: 100%;
  /* object-fit: contain; */
  object-fit: cover;
  height: 270px;
  object-position: top;
}

.as_festival_box .img_box {
  padding: 20px;
  padding-bottom: 0;
}

.as_festival_box .content_box {
  padding: 20px;
}

.as_festival_box .content_box h4 {
  font-size: 18px;
  color: #040c29;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  text-transform: capitalize;
}

.as_festival_box .content_box p {
  color: #767c90;
  margin-bottom: 0;
  font-size: 14px;
}

.as_festival_box .content_box .discription {
  line-height: 20px;
  margin-top: 7px;
}

.festival_detail_inner {
  width: 100%;
  position: relative;
}

.festival_detail_inner .img-box {
  min-height: 200px;
  overflow: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
}

.festival_detail_inner .img-box img {
  max-width: 100%;
  width: 100%;
  object-fit: contain;
  height: 100%;
  border-radius: 15px;
}

.festival_detail_inner .img-box {
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 15px;
}

.festival_detail_inner .about_box h2 {
  color: #040c29;
  font-size: 22px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}

.festival_detail_inner .about_box p {
  color: #767c90;
  font-size: 16px;
  margin-bottom: 0;
}

.festival_detail_inner .about_box h4 {
  color: #b24b14;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-top: 8px;
}

.festival_detail_inner .about_box p.discripation {
  font-size: 15px;
  margin-top: 15px;
}

.match_making_form .match_making_box {
  background-image: linear-gradient(90deg, #b2147b, #b24b14);
  padding: 5px;
  padding-top: 0;
  border-radius: 10px;
  margin-bottom: 30px;
}

.match_making_form .match_making_box .match_form {
  background: #ffffff;
  padding: 20px;
  border-radius: 0 0 10px 10px;
}

.match_making_form .match_making_box > h4 {
  font-size: 22px;
  color: #ffffff;
  font-family: 'Inter', sans-serif;
  margin: 0;
  text-align: center;
  padding: 20px 0px;
}

.call_btn_inner {
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.call_btn_inner .call_btn {
  border: 1px solid #1aa260;
  color: #1aa260;
  font-weight: 500;
  padding: 2px 15px !important;
  border-radius: 5px;
}

.call_btn_inner button.btn.call_btn {
  border: 1px solid #1aa260;
  color: #1aa260;
}

/*festival_css_close*/

/*wallet_css*/
.as_wallet_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.as_wallet_heading h1 {
  margin-bottom: 0;
}

.as_wallet_heading .wallet_prize {
  text-align: center;
}

.as_wallet_heading .wallet_prize p {
  margin-bottom: 5px;
  text-align: center;
  /* margin-right: -265px; */
}

.as_wallet_heading .wallet_prize h4 {
  margin-bottom: 0;
  color: #b24b14;
  font-size: 30px;
  /* margin-right: -265px; */
}

.wallet_area .add_history {
  background: #fff;
  padding: 30px 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  height: 100%;
}

.wallet_area .add_history .table tr td:last-child {
  text-align: right;
  color: #767c90 !important;
  font-size: 14px !important;
}

.wallet_area .add_history h2 {
  color: #b24b14;
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  text-transform: inherit;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 1px solid #c8c8c8;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

.wallet_area .add_history .table tr td {
  color: #040c29;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 15px;
}

.wallet_area .recharge_area {
  background: #fff;
  padding: 30px 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  height: 100%;
}

.wallet_area .recharge_area h2 {
  color: #b24b14;
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  text-transform: inherit;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 1px solid #c8c8c8;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

.wallet_area .recharge_plan h4,
.order_summary h4 {
  color: #886459;
  font-size: 19px;
  font-family: 'Inter', sans-serif;
}

.wallet_area .recharge_plan .form-control {
  border: 1px solid #979ba7;
  font-weight: bold;
}

.order_summary table.table tr td {
  color: #767c90;
  font-weight: 500;
}

.order_summary .footer td {
  color: #11982b !important;
  font-weight: 600 !important;
}

ul.recharge_filter.nav {
  margin: 0 -5px;
  margin-top: 15px;
}

ul.recharge_filter li {
  width: 15%;
  margin: 5px;
}

ul.recharge_filter.nav li label {
  border: 1.5px solid #b24b14;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

ul.recharge_filter.nav li input {
  position: absolute;
  left: -99999em;
  top: -99999em;
}

ul.recharge_filter.nav li input + label {
  cursor: pointer;
  border: 1.5px solid #b24b14;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  position: relative;
}

ul.recharge_filter.nav li input + label:hover {
  background-color: #eee;
}

ul.recharge_filter.nav li input:checked + label {
  background-color: #b24b14;
  border: none;
  color: #fff;
}

ul.recharge_filter li.active {
  background: #b24b14;
  color: #fff;
}

.recharge_pay .as_btn {
  color: #000000;
  font-weight: 600;
}

ul.recharge_filter.nav li input {
  display: none;
}

/*wallet_css_close*/

/*talk_details*/
#full-stars-example .rating-group {
  display: inline-flex;
}

#full-stars-example .rating__icon {
  pointer-events: none;
}

#full-stars-example .rating__input {
  position: absolute !important;
  left: -9999px !important;
}

#full-stars-example .rating__label {
  cursor: pointer;
  padding: 0 0.1em;
  font-size: 2rem;
  padding-right: 15px;
}

#full-stars-example .rating__icon--star {
  color: orange;
}

#full-stars-example .rating__icon--none {
  color: #eee;
}

#full-stars-example
  .rating__input--none:checked
  + .rating__label
  .rating__icon--none {
  color: red;
}

#full-stars-example
  .rating__input:checked
  ~ .rating__label
  .rating__icon--star {
  color: #ddd;
}

#full-stars-example .rating-group:hover .rating__label .rating__icon--star {
  color: orange;
}

#full-stars-example .rating__input:hover ~ .rating__label .rating__icon--star {
  color: #ddd;
}

#full-stars-example
  .rating-group:hover
  .rating__input--none:not(:hover)
  + .rating__label
  .rating__icon--none {
  color: #eee;
}

#full-stars-example
  .rating__input--none:hover
  + .rating__label
  .rating__icon--none {
  color: red;
}

#full-stars-example h4 {
  font-family: 'Inter', sans-serif;
  margin-bottom: 15px;
}

.form-group.talk_detail_comment label {
  font-size: 22px;
  padding: 12px 0px;
}

.talk_comment_section > ul > li .as_comnt_detail .as_btn {
  position: relative;
  right: auto;
  top: 0;
  padding: 0 10px;
}

.talk_comment_section .rating_box span {
  color: #e8ba22;
  font-size: 30px;
}

.heroscope_heading img {
  width: 75px;
  max-width: 100%;
  margin-right: 20px;
}

.heroscope_heading .heroscope_name h4 {
  color: #040c29;
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0;
}

.heroscope_heading .heroscope_name p {
  color: #b24b14;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 5px;
}

.heroscope_box {
  border-bottom: 1px solid #979ba7;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.heroscope_box p {
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 16px;
  line-height: 30px;
}

/*talk_details_close*/

.notifiction_icon .dropdown-toggle::after {
  display: none;
}

.notifiction_icon button {
  color: #ffffff;
  font-size: 18px;
  position: relative;
}

li.notifiction_icon.dropdown {
  margin-left: 0;
}

.notifiction_icon .count {
  position: absolute;
  width: 12px;
  height: 12px;
  display: block;
  background: #211a49;
  font-size: 8px;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: -3px;
  right: -4px;
}

.notifiction_icon .btn:hover {
  color: #ffffff;
}

.notifiction_icon .btn-check:focus + .btn,
.notifiction_icon .btn:focus {
  outline: 0;
  box-shadow: none;
}

.notifiction_inner h1 {
  background-image: linear-gradient(150deg, #b2147b, #b24b14);
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
  font-size: 22px;
  padding: 15px 0;
  color: #fff;
  font-weight: 600;
}

.notifiction_inner .notifiction_box {
  display: flex;
  align-items: flex-start;
  margin: 0 10px;
  border-bottom: 1px solid #979ba7;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.notifiction_inner .content_box h4 {
  font-size: 15px;
  color: #040c29;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  margin-bottom: 0;
}

.notifiction_inner .content_box p {
  font-size: 12px;
  line-height: 18px;
  color: #767c90;
  margin-bottom: 0;
  padding: 4px 0;
}

.notifiction_inner .img_box img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}

.notifiction_inner .content_box h5 {
  margin-bottom: 0;
  font-size: 12px;
  color: #767c90;
}

.notifiction_inner .content_box h5 span {
  margin-right: 6px;
}

.notifiction_canvas {
  position: fixed;
  bottom: 0;
  z-index: 99999999;
}

.notifiction_canvas .offcanvas-body {
  flex-grow: 1;
  padding: 0;
  overflow-y: auto;
}

.notifiction_inner .content_box {
  width: 80%;
}

.user_box button {
  display: flex;
  align-items: center;
  position: relative;
  top: 10px;
}

.user_box button img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.user_box button p {
  margin-bottom: 0;
  margin-left: 10px;
  color: #fff;
  font-size: 14px;
}

.user_box .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  margin-top: 4px;
  color: #fff;
}

.user_box.btn-check:focus + .btn,
.user_box .btn:focus {
  outline: 0;
  box-shadow: none;
}

.user_box .dropdown-menu {
  padding: 10px 0;
}

.user_box .dropdown-menu.show li a {
  padding: 10px 15px;
  text-decoration: none;
  color: #737373;
  font-size: 1rem;
  display: flex;
}

.user_box ul.dropdown-menu li a span:first-child {
  margin-right: 10px;
  font-size: 18px;
  display: inline-block;
  width: 25px;
}

.user_box ul.dropdown-menu li a span img {
  width: 18px;
}

.chat_form_inner {
  background: #ffffff;
  padding: 30px 20px;
  border-radius: 15px;
}

.chat_form_inner .form-group label {
  font-size: 16px;
  color: #979ba7;
  margin-bottom: 8px;
}

.chat_form_inner .chat_intake_btn a {
  color: #000000;
  font-weight: 600;
}

.chat_window_inner {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  height: calc(100% - 50px);
  border-radius: 5px;
  box-shadow: 0 4px 13px -5px rgb(0 0 0 / 20%);
}

.chat_window_inner .msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  color: #fff;
  background-image: linear-gradient(150deg, #b2147b, #b24b14);
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.chat_window_inner .msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.chat_window_inner .msger-chat::-webkit-scrollbar {
  width: 6px;
}

.chat_window_inner .msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}

.chat_window_inner .msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

.chat_window_inner .msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.chat_window_inner .msg:last-of-type {
  margin: 0;
}

.chat_window_inner .msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
}

.chat_window_inner .msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: #ececec;
}

.chat_window_inner .msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.chat_window_inner .msg-info-name {
  margin-right: 10px;
  font-weight: bold;
  text-transform: capitalize;
}

.chat_window_inner .msg-info-time {
  font-size: 0.85em;
}

.chat_window_inner .left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.chat_window_inner .right-msg {
  flex-direction: row-reverse;
}

.chat_window_inner .right-msg .msg-bubble {
  background: #b22163;
  color: #fff;
  border-bottom-right-radius: 0;
}

.chat_window_inner .right-msg .msg-img {
  margin: 0 0 0 10px;
}

.chat_window_inner .msger-inputarea {
  display: flex;
  padding: 10px;
  background: #b22064;
}

.chat_window_inner .msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}

.chat_window_inner .msger-input {
  flex: 1;
  background: #ddd;
}

.chat_window_inner .msger-send-btn {
  margin-left: 10px;
  background: var(--secondary-color);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
  border-radius: 5px;
}

.chat_window_inner .msger-send-btn:hover {
  background: #01a1a4;
}

.chat_window_inner .msger-chat {
  background-color: #fcfcfe;
  background-image: url(../public/images/chat_back.png);
  width: 100%;
  max-height: 400px;
  min-height: 400px;
  overflow-y: scroll;
  background-repeat: repeat;
}

.chat_window_inner .msger-header-title img {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  margin-right: 15px;
}

.user_name h4 {
  font-size: 15px;
  text-align: left;
  font-family: 'Inter', sans-serif;
  margin-bottom: 0;
  font-weight: 600;
}

.user_name h5 {
  font-size: 14px;
  text-align: left;
  font-family: 'Inter', sans-serif;
  margin-bottom: 0;
  margin-top: 2px;
}

.user_name p {
  font-size: 14px;
  text-align: left;
  font-family: 'Inter', sans-serif;
  margin-bottom: 0;
}

.profile_setting_inner {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.profile_setting_inner .profile_info img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: auto;
  display: block;
  margin-bottom: 20px;
}

.profile_setting_inner .profile_info h4 {
  text-align: center;
  color: #4b3b5a;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}

.profile_setting_inner .profile_tab_box {
  border-right: 1px solid #d1d1d1;
  padding: 30px 0px;
  width: 100%;
  height: 100%;
}

.profile_setting_inner .profile_tab_box ul.tabs-left {
  list-style: none;
  padding: 0;
  margin-top: 20px;
  width: 100%;
  display: block !important;
}

.profile_setting_inner .profile_tab_box ul.tabs-left li a {
  text-align: left;
  display: flex;
  padding: 12px 0px;
  padding-left: 35px;
  border-bottom: 1px solid #d1d1d1;
  color: #040c29;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}

.profile_setting_inner .profile_tab_box ul.tabs-left li a.active {
  background-image: linear-gradient(150deg, #b2147b, #b24b14);
  color: #fff !important;
}

.profile_setting_inner .profile_tab_box ul.tabs-left li a span {
  width: 25px;
  display: block;
}

.profile_setting_inner ul.tabs-left li:first-child {
  border-top: 1px solid #d1d1d1;
}

.profile_setting_inner .profile_box {
  padding: 45px 15px;

  height: 100%;
  padding-right: 30px;
}

.profile_box .form-control::placeholder {
  color: #221c4a;
  font-weight: 500;
}

.profile_box .profile_edit button {
  color: #000;
  font-weight: 600;
}

.avatar-upload {
  position: relative;
  max-width: 160px;
  margin: 30px 0;
}

.edit_profile_box {
  padding: 30px 15px;
  padding-right: 30px;
}

.avatar-upload .avatar-edit {
  position: absolute;
  /* right: 12px; */
  right: 5px;
  z-index: 1;
  top: 10px;
  /* height: 100%; */
}

.avatar-upload .avatar-edit img {
  width: 100%;
}

.avatar-upload .avatar-edit input {
  display: none;
}

img.imgProfile {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.edit_profile_image .edit_image,
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  text-align: center;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #dce1e7;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  color: red;
  z-index: 2;
  margin-bottom: -25px;
}

.edit_profile_image label.edit_image {
  margin-left: 80px;
}

.edit_profile_image button.edit_image {
  font-size: 24px;
  padding: 0;
}

.avatar-upload .avatar-edit input + label svg {
  margin-top: 4px;
}

.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input + label:after {
  content: '\f040';
  font-family: 'FontAwesome';
  color: #b22261;
  position: absolute;
  top: 4px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 150px;
  height: 150px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 18%);
}

.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.order_history_area .nav.nav-tabs li a {
  padding: 15px 15px;
  color: #767c90;
  font-size: 18px !important;
}

.order_history_area .nav-tabs {
  border-bottom: none;
}

.order_history_area .nav-tabs .nav-item.show .nav-link,
.order_history_area .nav-tabs .nav-link.active {
  color: #495057;
  border-color: transparent;
  color: #886459 !important;
  border-bottom: 2px solid #886459;
  font-weight: 500;
}

.order_history_area .nav-tabs .nav-link {
  margin-bottom: -5px;
  background: 0 0;
  border: none;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.call_history_box p {
  text-align: center;
  font-size: 22px;
  color: #767c90;
}

.chat_history_info {
  background-color: #ffffff;
  padding: 20px 0;
  margin-top: 30px;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  border-top: 3px solid #b21f65;
}

.chat_history_info img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: auto;
  display: block;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  margin-bottom: 15px;
}

.chat_history_info h4 {
  text-align: center;
  font-size: 22px;
  color: #b24b14;
  font-family: 'Inter', sans-serif;
  margin-bottom: 0;
}

.chat_history_info p {
  color: #040c29;
  font-size: 18px;
  font-family: 'Inter', sans-serif;
  text-align: center;
  margin-top: 5px;
}

.chat_history_info .info_list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.chat_history_info ul.info_list li {
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #bababa;
  padding: 8px 27px;
  color: #767c90;
}

.chat_history_info ul.info_list li span.left {
  width: 50%;
  display: block;
}

.chat_history_info ul.info_list li span.right {
  width: 50%;
  display: block;
}

.share_with_freind button {
  display: flex;
  align-items: center;
}

.share_with_freind button img {
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin-bottom: 0;
  margin-right: 10px;
}

.share_with_freind button {
  display: flex;
  align-items: center;
  border: 1px solid #b22064;
  padding: 5px 12px !important;
  margin-top: 20px;
  color: #221c4a;
}

.support_chat_form {
  background-image: linear-gradient(90deg, #b2147b, #b24b14);
  padding: 5px;
  padding-top: 0;
  border-radius: 10px;
  margin-bottom: 30px;
  width: 90%;
  margin: auto;
}

.support_chat_form h4 {
  font-size: 22px;
  color: #ffffff;
  font-family: 'Inter', sans-serif;
  margin: 0;
  text-align: center;
  padding: 20px 0px;
}

.support_Chat_inner {
  background: #ffffff;
  padding: 20px;
  border-radius: 0 0 10px 10px;
}

.upload_img .form-control {
  display: flex;
  align-items: center;
}

.support_Chat_inner textarea.form-control {
  height: 120px;
  resize: none;
  padding-top: 15px;
  outline: none;
  box-shadow: none !important;
  background: #ffffff;
  color: #a5a9af;
  padding: 0 10px;
  border: 1px solid #b3b3b3;
}

.profile_inner_text ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.profile_inner_text ul li {
  display: flex;
  align-items: start;
  margin-bottom: 15px;
}

.profile_inner_text ul li .first {
  display: block;
  width: clamp(200px, 200px, 200px);
  font-weight: 600;
  color: #4b3b5a;
  white-space: nowrap;
  max-width: 200px;
  min-width: 200px;
}

.profile_inner_text ul li .last {
  color: #767c90;
  font-weight: 500;
  line-break: auto;
  text-align: justify;
}


/* Preloader CSS Start*/
.preloader_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  transition: background-color 0.3s;
}

.preloader_wrapper .astro_preloader {
  width: 8em;
  height: 16em;
  position: absolute;
  left: calc(50% - 4em);
  top: calc(50% - 15em);
}

.preloader_wrapper .astro_preloader__ring {
  transition: stroke 0.3s;
}

.preloader_wrapper .astro_preloader__worm1,
.preloader_wrapper .astro_preloader__worm2 {
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

.preloader_wrapper .astro_preloader__worm1 {
  animation-name: worm1;
}

.preloader_wrapper .astro_preloader__worm2 {
  animation-name: worm2;
  visibility: hidden;
}

/* Animtions */
@keyframes worm1 {
  from {
    animation-timing-function: ease-in-out;
    stroke-dashoffset: -87.96;
  }

  20% {
    animation-timing-function: ease-in;
    stroke-dashoffset: 0;
  }

  60% {
    stroke-dashoffset: -791.68;
    visibility: visible;
  }

  60.1%,
  to {
    stroke-dashoffset: -791.68;
    visibility: hidden;
  }
}

@keyframes worm2 {
  from,
  60% {
    stroke-dashoffset: -87.96;
    visibility: hidden;
  }

  60.1% {
    animation-timing-function: cubic-bezier(0, 0, 0.5, 0.75);
    stroke-dashoffset: -87.96;
    visibility: visible;
  }

  77% {
    animation-timing-function: cubic-bezier(0.5, 0.25, 0.5, 0.88);
    stroke-dashoffset: -340;
    visibility: visible;
  }

  to {
    stroke-dashoffset: -669.92;
    visibility: visible;
  }
}

/* Preloader CSS End*/

/* For responsive tables*/
.table-responsive {
  overflow-y: hidden;
}

/* For Preloader Overlay*/

.preloaderOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.overlayLoader {
  margin-top: 293px;
  text-align: center;
}

/* CSS for Switch Button*/
.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* width: 100px;
  height: 50px; */
  width: 85px;
  height: 40px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  float: right;
  /* margin-right: 100px; */
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  /* width: 45px;
  height: 45px; */
  width: 38px;
  height: 36px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}

h4.as_subheading {
  color: #000000;
  font-size: 22px;
  font-family: 'Inter', sans-serif;
  margin-bottom: 14px;
  font-weight: 600;
}

p.as_subheading {
  color: #000000;
}

button.as_btn.pay_btn {
  height: 44px;
  margin-right: -48px;
}

.detailsCard {
  text-align: justify;
  margin-bottom: 4px;
  word-spacing: -1px;
}

.product_box .as_icon {
  font-size: 32px;
}

span.shownpwd {
  color: black;
  cursor: pointer;
  margin-top: -38px;
  float: right;
  font-size: 20px;
  padding-right: 10px;
}

.fixed_toast {
  position: fixed;
  z-index: 999999;
  top: 65px;
  right: 30px;
  display: flex;
}

.fixed_toast .toast-header {
  margin-right: 10px;
  display: flex;
  justify-content: center;
}

.fixed_toast .toast-header button {
  opacity: 1;
  margin: 0;
  padding: 0;
}

.otp {
  float: right;
  color: blue;
  margin-top: 18px;
  margin-left: -95px;
}

.otp p {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.otpfixed_toast {
  position: fixed;
  z-index: 999999;
  top: -20px;
  left: 400px;
  display: flex;
}

.otpfixed_toast .toast-header {
  display: flex;
  justify-content: center;
}

.otpfixed_toast .toast-header button {
  opacity: 1;
  margin: 0;
  padding: 0;
}

.login_toast {
  position: fixed;
  z-index: 999999;
  top: -26px;
  left: 492px;
  display: flex;
}

.login_toast .toast-header {
  display: flex;
  justify-content: center;
}

.login_toast .toast-header button {
  opacity: 1;
  margin: 0;
  padding: 0;
}

.signup_toast {
  position: fixed;
  z-index: 999999;
  top: 25px;
  left: 480px;
  display: flex;
}

.signup_toast .toast-header {
  display: flex;
  justify-content: center;
}

.signup_toast .toast-header button {
  opacity: 1;
  margin: 0;
  padding: 0;
}

@media (min-width: 768px) and (max-width: 1280px) {
  .signup_toast {
    position: fixed;
    z-index: 999999;
    top: -30px;
    left: 50px;
    display: flex;
  }

  .signup_toast .toast-header {
    display: flex;
    justify-content: center;
  }

  .signup_toast .toast-header button {
    opacity: 1;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 568px) {
  .signup_toast {
    position: fixed;
    z-index: 999999;
    top: -30px;
    left: 5px;
    display: flex;
  }

  .signup_toast .toast-header {
    display: flex;
    justify-content: center;
  }

  .signup_toast .toast-header button {
    opacity: 1;
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .otpfixed_toast {
    position: fixed;
    z-index: 999999;
    top: -30px;
    left: 50px;
    display: flex;
  }

  .otpfixed_toast .toast-header {
    display: flex;
    justify-content: center;
  }

  .otpfixed_toast .toast-header button {
    opacity: 1;
    margin: 0;
    padding: 0;
  }


}

@media (max-width: 568px) {
  .otpfixed_toast {
    position: fixed;
    z-index: 999999;
    top: -30px;
    left: 5px;
    display: flex;
  }

  .otpfixed_toast .toast-header {
    display: flex;
    justify-content: center;
  }

  .otpfixed_toast .toast-header button {
    opacity: 1;
    margin: 0;
    padding: 0;
  }

  /* .otpfixed_toast .toast-body {
    margin-left: 50px;
  } */
}
.as_input_feild input {
  width: 100%;
}
.login_form p.text-danger {
  margin: 0;
  font-size: 12px;
}
.verifymsgg p {
  color: red;
  margin-top: -150px;
  text-align: center;
}
/* .ast_select__menu > div{
  margin: 15px;
} */
.product_box .as_icon {
  font-size: 20px;
}
.limitcar {
  white-space: nowrap;
  min-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  width: 100%;
  display: block;
  margin-left: 5px;
}
.availinput input {
  width: 100%;
  height: 50px;
  outline: none;
  box-shadow: none !important;
  background: #ffffff;
  color: #000000;
  padding: 0 10px;
  border: 1px solid #ccc;
}
.orderTable tr > th {
  color: #9898a5;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1.5px;
}

@media screen and (max-width: 500px) {
  .astrologer_heading h4 {
    font-size: 15px;
    margin: 0;
  }

  .astrologer_heading {
    padding: 8px 0;
  }
  .astrologer_heading > div {
    align-items: center;
  }
  .as_padderTop50 {
    padding-top: 20px;
  }
  .as_heading {
    font-size: 18px;
    padding-bottom: 10px;
  }
  .as_font14 {
    font-size: 13px;
    line-height: 0;
  }
  .as_service_box {
    padding: 20px;
    margin-top: 0;
  }
  .support_chat_form {
    width: 95%;
  }
  .astrologer_heading h1,
  .as_subheading {
    font-size: 18px;
  }
  .profile_setting_inner .profile_tab_box ul.tabs-left li a {
    padding-left: 15px;
  }
  button.as_btn.log_btn {
    margin: auto;
  }
  .orderTable tr > th,
  .orderTable tr > td {
    white-space: nowrap;
    font-size: 14px;
  }
  .support_Chat_inner {
    padding: 10px;
  }
  .product_box .as_icon {
    font-size: 20px;
  }
  .support_chat_form h4 {
    font-size: 18px;
    padding: 10px 0px;
  }
  .react-switch-label {
    height: 20px;
    width: 50px;
  }
  .react-switch-label .react-switch-button {
    height: 16px;
    width: 16px;
  }
  .call_history_box p {
    text-align: center;
    font-size: 18px;
    color: #767c90;
    background: #fff;
    padding: 50px;
    box-shadow: -1px 1px 20px 2px #00000014;
  }
  .limitcar {
    white-space: nowrap;
    min-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    width: 100%;
    display: block;
  }
  section.astrologer_area,
  .as_zodiac_single_wrapper {
    margin-top: 45px !important;
  }
  .as_logo {
    padding: 7px 13px;
}
}

@media screen and (max-width: 820px) {
  .as_zodiac_single_wrapper,
  section.astrologer_area {
    margin-top: 60px;
  }
  .support_chat_form {
    width: 95%;
  }
  .orderTable tr > th,
  .orderTable tr > td {
    white-space: nowrap;
    font-size: 14px;
  }
}

a.productprice {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.toast-body {
  color: #fff;
}
.price {
  font-size: 13px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* .form-group.form_effect {
  margin-top: 35px;
} */

.backtologin {
  cursor: pointer;
}

.support_scroll .table-responsive.orderTable {
  overflow-y: auto;
  height: auto;
}

.support_Chat_inner.user_details_table table tr td:first-child {
  width: 235px;
}

.gallery_image {
  max-width: 20%;
  max-height: 20%;
}

.upload-container {
  /* background-color: rgb(224, 223, 223); */
  height: 100px;
  width: 100%;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* astrologer_feeds*/

.astro_feed_sec .pseudo-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.astro_feed_sec .pseudo-search .input_box {
  box-shadow: 0px 6px 12px #433f5724;
  background: #fff;
  padding: 10px 10px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.astro_feed_sec .pseudo-search button {
  background: #e8ba22;
  height: 56px;
  border-radius: 0;
  font-size: 12px;
  font-weight: 600;
  padding: 0 23px;
  box-shadow: 0px 6px 12px #433f5724;
}

.astro_feed_sec .pseudo-search .input_box img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 13px;
}

.astro_feed_sec .pseudo-search .input_box input {
  font-size: 12px;
  border: none;
  width: 90%;
  color: #040c29;
  font-weight: 500;
}

.astro_feed_sec .pseudo-search i.fa.fa-picture-o {
  color: #b8bbd1;
}

.card._feed_box {
  background: #fff !important;
  box-shadow: 0px 6px 12px #433f5724;
  border-radius: 0;
  border: none;
}

.card._feed_box .card-header {
  background: #fff;
  border: none;
  padding: 15px 20px;
  padding-bottom: 0;
}

.card._feed_box .card-header .user_info h4 {
  color: #040c29;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  font-family: 'Inter', sans-serif;
}

.card._feed_box .card-header .user_info > img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}

.card._feed_box .card-header .user_info p {
  font-size: 12px;
  color: #767c90;
  margin-bottom: 0;
  font-weight: 500;
}

.card._feed_box .card-header > a {
  color: #767c90;
}

.card._feed_box .card-body {
  padding: 0px 20px;
  font-size: 14px;
  border: none;
  padding-top: 5px;
}

.card._feed_box .card-body p {
  font-size: 13px;
  color: #040c29;
  font-weight: 600;
}

.card._feed_box .card-body img {
  width: 100%;
}
.card._feed_box .card-footer {
  border: none;
  padding: 8px 20px;
}

.card._feed_box .card-footer ul li a {
  font-size: 12px;
  color: #040c29;
  font-weight: 600;
}

.card._feed_box .card-footer ul li {
  margin-right: 30px;
  font-size: 12px;
  color: #040c29;
  font-weight: 600;
}

.card._feed_box .card-footer i.fa.fa-heart {
  color: #b21414;
}

.card._feed_box .card-footer ul li i {
  font-size: 16px;
}

.feeds_requset_sec {
  top: 95px;
  height: auto;
  width: 100%;
  min-height: auto;
  overflow-y: auto;
  position: -webkit-sticky;
  position: sticky;
}

.feeds_requset_sec .wall_box {
  box-shadow: 0px 6px 12px #433f5724;
  background: #fff;
  padding: 18px 20px;
}

.feeds_requset_sec .wall_box h5 {
  font-size: 15px;
  margin-bottom: 0;
  color: #040c29;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  margin-left: 10px;
}

.feeds_requset_sec .wall_box i {
  color: #b24b14;
  font-weight: 500;
  font-size: 20px;
}

.feeds_requset_sec ._chat_request > h4 {
  letter-spacing: 0.6px;
  color: #767c90;
  text-transform: uppercase;
  font-size: 13px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
}

.feeds_requset_sec ._chat_request ul {
  padding: 0;
  background: #fff;
  box-shadow: 0px 6px 12px #433f5724;
}

.feeds_requset_sec ._chat_request ul li img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}

.feeds_requset_sec ._chat_request ul li {
  border-bottom: 1px solid #433f5724;
  padding: 10px 10px;
}

.feeds_requset_sec ._chat_request ul li h4 {
  color: #040c29;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
  font-family: 'Inter', sans-serif;
}

.feeds_requset_sec ._chat_request ul li p {
  font-size: 11px;
  color: #767c90;
  margin-bottom: 0;
  font-weight: 500;
}

.feeds_requset_sec ._chat_request ul li .d-flex.align-items-center a {
  background: #f6bf3a;
  border-radius: 2px;
  font-size: 10px;
  color: #040c29;
  font-weight: 600;
  padding: 0px 10px !important;
}

.feeds_requset_sec ._chat_request ul li a.reject {
  background: #b24b14 !important;
  color: #fff !important;
}

.feeds_requset_sec ._call_request > h4 {
  letter-spacing: 0.6px;
  color: #767c90;
  text-transform: uppercase;
  font-size: 13px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
}

.feeds_requset_sec ._call_request ._call_req_inn {
  box-shadow: 0px 6px 12px #433f5724;
  background: #11982b;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.feeds_requset_sec ._call_request ._call_req_inn h4 {
  font-size: 15px;
  color: #ffffff;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  margin-bottom: 0px;
}

.feeds_requset_sec ._call_request ._call_req_inn p {
  font-size: 11px;
  color: #f8e2c5;
  margin-bottom: 0;
  font-weight: 500;
}

.feeds_requset_sec ._call_request a.as_btn.mt-4 {
  margin-left: 0;
  padding: 5px 20px;
  height: auto;
  color: #040c29;
  font-weight: 600;
  font-size: 12px;
  border-radius: 2px;
}

section.as_zodiac_single_wrapper.astrologer_feed_area {
  background: #fff;
  background-image: url(..//public/images/why_choose_us.jpg);
  background-repeat: repeat;
  background-size: 60%;
  background-attachment: fixed;
}

.astro_feed_sec .pseudo-search .input_box input:focus-visible {
  border: none;
  outline: none;
}

.astro_feed_sec .feed_comments_sec ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.astro_feed_sec .feed_comments_sec ul .card-header {
  background: transparent;
  border: none;
  padding: 0;
  padding-bottom: 0;
}

.astro_feed_sec a.comments {
  font-size: 13px;
  color: #767c90;
  font-weight: 500;
  display: block;
  margin-top: 10px;
}

.feed_comments_sec > ul > li p {
  font-size: 13px;
  color: #040c29;
  font-weight: 600;
  margin-bottom: 0;
}

.feed_comments_sec > ul > li a.reply {
  font-size: 12px !important;
  color: #767c90 !important;
  display: inline-block;
}

.feed_comments_sec ul li {
  margin: 0 !important;
}

.feed_comments_sec > ul > li {
  border-bottom: 1px solid #979ba740;
  padding: 10px 0;
}

.feed_comments_sec ul.fcs_reply_sec li h4 {
  color: #040c29;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  font-family: 'Inter', sans-serif;
}

.feed_comments_sec ul.fcs_reply_sec li p {
  font-size: 12px;
  color: #767c90;
  margin-bottom: 0;
  font-weight: 500;
}

.feed_comments_sec ul.fcs_reply_sec li .des.d-flex p {
  font-size: 13px;
  color: #040c29;
  font-weight: 600;
  margin-bottom: 0;
  width: 95%;
}

.feed_comments_sec ul.fcs_reply_sec li .user_info h5 {
  color: #767c90;
  font-size: 11px;
  font-style: italic;
}

.feed_comments_sec ul.fcs_reply_sec {
  width: 100%;
  background: #f0f0f0;
  padding: 10px !important;
  border-radius: 10px;
  margin-top: 10px !important;
}

.feed_comments_sec ul.fcs_reply_sec li {
  margin-right: 0 !important;
}

.feed_comments_sec i.fa.fa-ellipsis-h {
  color: #979ba7;
}

.feed_comments_sec .reply_input_sec {
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 30px;
}

.feed_comments_sec .reply_input_sec input {
  background: transparent;
  border: none;
  font-size: 12px;
  color: #040c29;
  font-weight: 600;
  width: 90%;
}

.feed_comments_sec .reply_input_sec input::placeholder {
  color: #040c29;
}

.feed_comments_sec .reply_input_sec button.btn {
  padding: 0;
  font-size: 12px;
  color: #4b3b5a;
  font-weight: 600;
}
.pro_gallery_sec > div > div h6 {
  text-align: left;
  color: #4b3b5a;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 20px;
  margin-top: 8px;
}
.pro_gallery_sec .upload-container {
  border: 1px dashed #767c90;
  width: 350px;
  margin: auto;
  color: #767c90;
}

.pro_gallery_sec .upload-container svg {
  margin-right: 12px;
  color: #767c90;
}

.pro_gallery_sec .image-item {
  margin-top: 20px;
}
.pro_gallery_sec .image-item img {
  width: 100% !important;
  object-fit: cover;
  height: 100px;
  border: 1px solid #767c90;
  padding: 2px;
  border-radius: 0px;
  margin-bottom: 5px;
}

.pro_gallery_sec button.btn.btn-primary {
  background-image: linear-gradient(150deg, #b2147b, #b24b14);
  color: #fff !important;
  border: none;
  border-right: 3px solid #fff !important;
  border-radius: 0;
  font-size: 12px;
}

.pro_gallery_sec button.btn.btn-primary:last-child {
  border-right: none !important;
}

.pro_gallery_sec .scroll .nav li {
  width: 33%;
  padding: 0 6px;
  position: relative;
  margin-bottom: 10px;
}

.pro_gallery_sec ul.nav li img {
  width: 100%;
  height: 190px;
  object-fit: cover;
  border-radius: 10px;
  object-position: center;
}

.pro_gallery_sec ul.nav li button {
  border-radius: 2px;
  font-size: 11px;
  font-weight: 600;
  padding: 0px 9px !important;
  background: #a0162c !important;
  color: #fff !important;
  border: none;
  line-height: 19px;
  position: absolute;
  right: 21px;
  top: 11px;
}

.feeds_requset_sec .react-switch-label {
  height: 20px;
  width: 40px;
}

.feeds_requset_sec .react-switch-label .react-switch-button {
  height: 16px;
  width:16px;
  pointer-events: none;
}
/* astrologer_feeds_close*/

@media screen and (max-width: 1399px) {

.modal-dialog.modal-lg {
  width: 650px !important;
}

img.post_image {
  height: 210px !important;
  object-fit: contain;
}

.modal-body {
  padding: 9px 20px;
}

}

@media screen and (max-width: 1199px) {
  .astro_feed_sec .pseudo-search .input_box input {
    font-size: 12px;
    border: none;
    width: 88%;
    color: #040c29;
    font-weight: 500;
  }

  .as_zodiac_single_wrapper {
    background-color: #f2f2f2;
    color: #000000;
    margin-top: 60px;
}
.as_header_wrapper .as_logo img {
  width: 110px;
}
 .as_logo {
  padding: 4px 30px;
}
section.astrologer_area {
  margin-top: 65px;
}
}

@media screen and (max-width: 991px) {
  .astro_feed_sec .pseudo-search .input_box input {
    width: 80%;
  }
}

@media screen and (max-width: 767px) {
  .pro_gallery_sec .scroll .nav li {
    width: 50%;
  }

  .pro_gallery_sec ul.nav li img {
    width: 100%;
    height: 170px;
  }

  .astrologer_feed_inner .row {
    flex-direction: column-reverse;
}

.feeds_requset_sec .wall_box {
  box-shadow: 0px 6px 12px #433f5724;
  background: #fff;
  padding: 18px 20px;
  margin-bottom: 20px;
}

div#feeds-pagination {
  flex-wrap: wrap;
}

#feeds-pagination a.page-link {
  padding: 0 10px;
  font-size: 14px;
}

#feeds-pagination ul.pagination {
  margin-top: 13px;
}
}

@media screen and (max-width: 480px) {
  .astro_feed_sec .pseudo-search .input_box input {
    width: 73%;
  }

  .card._feed_box .card-footer ul li {
    margin-right: 15px;
  }

  .pro_gallery_sec .scroll .nav li {
    width: 50%;
  }

  .pro_gallery_sec ul.nav li img {
    width: 100%;
    height: 150px;
  }
}

.error_toast {
  position: fixed;
  z-index: 999999;
  top: 65px;
  right: 30px;
  display: flex;
  background-color: #d81818;
  color: white;
}

.error_toast .toast-header {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  background-color: #d81818;
}

.error_toast .toast-header button {
  opacity: 1;
  margin: 0;
  padding: 0;
}

svg.myWallPencil {
  color: #B24B14;
  font-weight: 500;
  font-size: 20px;
}

.card._feed_box .card-footer ul li svg {
  font-size: 16px;
}

#feeds-pagination .active, #dropdown-basic, #feeds-pagination .page-item.active .page-link{
  background-color: #B24B14;
  border-color: #B24B14;
  color: #fff;
}
#feeds-pagination .page-link{
  color: #B24B14;
}
.cursor-pointer{
  cursor: pointer;
}
.post-img-fluid{
  max-height: 100vh;
}

.chat-icon-show{
  position: absolute;
  display: block;
  right: -5px;
  width: 8px;
  height: 8px;
  background-color: var(--secondary-color);
  border-radius: 50% 50%;
}

.chat-icon-hide{
  display: none;
}

.chat-popup-header{
  text-align: center;
  background-image: linear-gradient(90deg, #b2147b, #b24b14);
  color: white;
}

.errorVal{
  color:#dc3545;
  font-size:0.875em;
  /* font-weight:420; */
  /* margin-left: 20px; */
  }
.detail-card{
  background-color:#f0f2f6;
  padding: 11px;
  border-radius: 4px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.question-item li {
    font-size: 13px;
    margin-bottom: 0;
    font-family: 'Inter', sans-serif;
}


span.status_success{
    background: green;
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
}

span.status_failed {
    background: red;
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
}


span.status_pending {
  background: var(--secondary-color);
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 15px;
}